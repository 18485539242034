import React from "react";
import { Form, Row, Col, Typography, Image, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import home from './img/home.svg';
import chair from './img/chair.svg';
import walk from './img/walk.svg';
import work from './img/work.svg';

const { Title, Text } = Typography;

const AppStep6 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
        await delay(500);
        next();
      };
    return(
        <Form 
            name="step6"
            onChange={submitFormData}
        >
            <Title level={2}>Décrivez votre journée type</Title>
            <Title level={5}>Les hommes de plus de 30 ans qui souhaitent perdre du poids ont besoin d'une approche plus personnalisée en fonction du mode de vie actuel.</Title>    
            <Form.Item 
                name="journeetype"
                defaultValue={values.journeetype}
                onChange={handleFormData("journeetype")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="Sédentaire" />
                        <label className="bodytypelbl" htmlFor="control_01">
                        <Row gutter={[8, 8]} align="middle">
                            <Col xs={{ span: 20 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>La plupart du temps à la maison</Text>
                            </Col>
                            <Col xs={{ span: 4}} sm={{ span: 2 }} md={{ span: 2 }}>
                                <Image width={30} src={home} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="Faible Actif" />
                        <label htmlFor="control_02">
                        <Row gutter={[8, 8]} align="middle">
                            <Col xs={{ span: 20 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Au bureau</Text>
                            </Col>
                            <Col xs={{ span: 4}} sm={{ span: 2 }} md={{ span: 2 }}>
                                 <Image width={30} src={chair} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="Actif" />
                        <label htmlFor="control_03">
                        <Row gutter={[8, 8]} align="middle">
                            <Col xs={{ span: 20 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Longues promenades quotidiennes</Text>
                            </Col>
                            <Col xs={{ span: 4}} sm={{ span: 2 }} md={{ span: 2 }}>
                                <Image width={30} src={walk} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_04" name="select" value="Athlète" />
                        <label htmlFor="control_04">
                        <Row gutter={[8, 8]} align="middle">
                            <Col xs={{ span: 20 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Travail physique</Text>
                            </Col>
                            <Col xs={{ span: 4}} sm={{ span: 2 }} md={{ span: 2 }}>
                                <Image width={30} src={work} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>
       

        <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button type="primary" shape="circle" onClick={prev} icon={<ArrowLeftOutlined />} />
                </Space>
                </Form.Item>
            </Col>
        </Row>
        </Form>
    );
}

export default AppStep6;