import React,  { useState }from "react";
import { Form, Row, Col, Button, Image, Typography, Radio, Space } from 'antd';
import { ArrowLeftOutlined, MinusCircleTwoTone  } from '@ant-design/icons';
import yes from './img/yes.svg';
import no from './img/no.svg';
import learn from './img/learn_pic.svg';
import confetti from './img/confetti.png';
const { Title, Text } = Typography;
const AppStep13 = ({ next, prev, handleFormData, values }) => {
  const submitFormData = (e) => {
    next();
  };

  const [isShowO, setIsShowO] = useState(false);
  const [isShowN, setIsShowN] = useState(false);
  const handleClickO = () => {setIsShowO(true); setIsShowN(false);}
  const handleClickN = () => {setIsShowO(false); setIsShowN(true);}

  return (
    <div>
      <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
        <Image width={400} src={learn} preview={false}/>
      </div>
      
      <Title level={2}>Croyez-vous que...</Title>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Space direction="vertical">
            <Text><MinusCircleTwoTone twoToneColor="#1890ff"/> Vous ne devriez pas manger après 18h</Text>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Space direction="vertical">
            <Text><MinusCircleTwoTone twoToneColor="#1890ff"/> Les bananes font grossir</Text>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Space direction="vertical">
          <Text><MinusCircleTwoTone twoToneColor="#1890ff"/> Manger du gras fait grossir</Text>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Space direction="vertical">
            <Text><MinusCircleTwoTone twoToneColor="#1890ff"/> Les friandises sont interdites</Text>
          </Space>
        </Col>
      </Row>
      <br />
      <Title level={2}>... si vous voulez perdre du poids ?</Title>
      <Form 
            name="step13"
            onFinish={submitFormData}
        >
      <Form.Item 
                name="pensees"
                defaultValue={values.pensees}
                onChange={handleFormData("pensees")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="Oui" onClick={handleClickO} />
                        <label className="bodytypelbl" htmlFor="control_01">
                        <Row gutter={[8, 8]}>
                            <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Oui</Text>
                            </Col>
                            <Col xs={{ span: 2}} sm={{ span: 2 }} md={{ span: 2 }}>
                                <Image width={20} src={yes} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="Non" onClick={handleClickN} />
                        <label htmlFor="control_02">
                        <Row gutter={[8, 8]}>
                            <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Non</Text>
                            </Col>
                            <Col xs={{ span: 2}} sm={{ span: 2 }} md={{ span: 2 }}>
                                 <Image width={20} src={no} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>
            <div className="center">
            <div className={isShowO ? "show" : "hide"}>
              <Title level={2}>Ce ne sont que des mythes !</Title>
              <Space direction="vertical">
                <Text ><Text strong>Vous voulez en savoir encore plus ?</Text> Nous avons créé pour vous une</Text>
                <Title level={4} mark>&nbsp; Cours du programme de perte de poids &nbsp;</Title>
                <Text>qui vous aidera à comprendre votre corps, à changer vos habitudes et à perdre du poids de la manière la plus efficace et la plus agréable.</Text>
              </Space>
            </div>
           
            <div className={isShowN ? "show" : "hide"}>
              <Title level={2}>Vous avez tout à fait raison!</Title>
              <Space direction="vertical">
                <Text ><Text strong>Vous voulez en savoir encore plus ?</Text> Nous avons créé pour vous une</Text>
                <Title level={4} mark>&nbsp; Cours du programme de perte de poids &nbsp;</Title>
                <Text>qui vous aidera à comprendre votre corps, à changer vos habitudes et à perdre du poids de la manière la plus efficace et la plus agréable.</Text>
              </Space>
            </div>
            </div><br />
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    </div>
  );
}
export default AppStep13;