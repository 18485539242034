import React from "react";
import { Form, Row, Col, Typography, Image, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ecto from './img/ecto.svg';
import muso from './img/muso.svg';
import endo from './img/endo.svg';
const { Title, Text } = Typography;

const AppStep5 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
        await delay(500);
        next();
    };
    
    return(
        <Form 
            name="step5"
            onChange={submitFormData}
        >
            <Title level={2}>Quel est votre type de corps?</Title>
            <Title level={5}>Chaque type de corps a une structure métabolique spécifique</Title>
            <Form.Item 
                name="bodytype"
                defaultValue={values.bodytype}
                onChange={handleFormData("bodytype")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="bodytype">
                    <Radio.Group>
                        <div className="bodytypediv">
                            <input type="radio" id="control_01" name="select" value="Ectomorphe" />
                            <label className="bodytypelbl" htmlFor="control_01">
                            <Row gutter={[8, 8]}>
                                <Col xs={{ span: 19 }} sm={{ span: 20 }} md={{ span: 20 }}>
                                    <Title level={4}>Ectomorphe</Title>
                                    <Text>Maigre et long, métabolisme rapide</Text>
                                </Col>
                                <Col xs={{ span: 5}} sm={{ span: 4 }} md={{ span: 4 }}>
                                    <Image width={60} src={ecto} preview={false}/>
                                </Col>
                            </Row>
                            </label>
                        </div>
                        <div className="bodytypediv">
                        <input type="radio" id="control_02" name="select" value="Mésomorphe" />
                            <label htmlFor="control_02">
                            <Row gutter={[8, 8]}>
                                <Col xs={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20 }}>
                                    <Title level={4}>Mésomorphe</Title>
                                    <Text>Musclé et bien bâti, métabolisme moyen</Text>
                                </Col>
                                <Col xs={{ span: 5}} sm={{ span: 2 }} md={{ span: 4 }}>
                                    <Image width={60} src={muso} preview={false}/>
                                </Col>
                            </Row>
                            </label>
                        </div>
                        <div className="bodytypediv">
                            <input type="radio" id="control_03" name="select" value="Endomorphe" />
                            <label htmlFor="control_03">
                            <Row gutter={[8, 8]}>
                                <Col xs={{ span: 19 }} sm={{ span: 20 }} md={{ span: 20 }}>
                                    <Title level={4}>Endomorphe</Title>
                                    <Text>Doux et rond, métabolisme lent</Text>
                                </Col>
                                <Col xs={{ span: 5}} sm={{ span: 4 }} md={{ span: 4 }}>
                                    <Image width={60} src={endo} preview={false}/>
                                </Col>
                            </Row>
                            </label>
                        </div>
                    </Radio.Group>
                </section>
            </Form.Item>
            <Row justify="start">
                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                    <Form.Item  className="steps-action">
                    <Space style={{ width: '100%' }}>
                        <Button type="primary" shape="circle" onClick={prev} icon={<ArrowLeftOutlined />} />
                        {/* <Button type="primary" htmlType="submit">
                            Continue
                        </Button> */}
                    </Space>
                    </Form.Item>
                </Col>
            </Row>
    </Form>
    );
}

export default AppStep5;