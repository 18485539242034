import React, { useState } from 'react';
import EditLeadVraisofaux from '../leadedit/editleadvraisofaux';
import { List, Typography, Button, Col, Row, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';
const { Title } = Typography;

const LeadVraisofaux = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    let vraisofaux = JSON.parse(dataLead[0].vraisofaux.split());
    return (
        <>
        <List
          size="small"
          header={
          <Row gutter={[24, 24]}>
            <Col span={21}>
              <Title level={5}>Tout ce qui est vrai pour vous :</Title>
            </Col>
            <Col span={3}>
              <Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />
            </Col>
          </Row>}
          bordered
          dataSource={vraisofaux}
          renderItem={(item) => <List.Item>{item}</List.Item>}
          />

        <Modal
            title="Sélectionnez tout ce qui est vrai pour vous :"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadVraisofaux dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
        );
    }
    export default LeadVraisofaux;