import React, { useState } from 'react';
import EditLeadDaily from '../leadedit/editleaddaily';
import { Descriptions, Button, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';

const LeadDaily = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    var journeetype;
    if(dataLead[0].journeetype === "Sédentaire"){ journeetype = "La plupart du temps à la maison" }
    else if(dataLead[0].journeetype === "Faible Actif"){ journeetype = "Au bureau" }
    else if(dataLead[0].journeetype === "Actif"){ journeetype = "Longues promenades quotidiennes" }
    else if(dataLead[0].journeetype === "Athlète"){ journeetype = "Travail physique" }

    var nrepas;
    if (dataLead[0].nrepas === "2-fois"){ nrepas = "2 Fois par jour" }
    else if (dataLead[0].nrepas === "3-fois"){ nrepas = "3 Fois par jour" }
    else if (dataLead[0].nrepas === "4-fois"){ nrepas = "4 Fois par jour" }
    else if (dataLead[0].nrepas === "5-fois"){ nrepas = "5 Fois par jour" }

    var entrainement_par_semaine
    if (dataLead[0].entrainement_par_semaine === "rien"){ entrainement_par_semaine = "Presque rien" }
    else if (dataLead[0].entrainement_par_semaine === "promenades"){ entrainement_par_semaine = "Seulement des promenades" }
    else if (dataLead[0].entrainement_par_semaine === "1-2-fois"){ entrainement_par_semaine = "1 à 2 fois par semaine" }
    else if (dataLead[0].entrainement_par_semaine === "3-4-fois"){ entrainement_par_semaine = "3 à 4 fois par semaine" }
    else if (dataLead[0].entrainement_par_semaine === "5-7-fois"){ entrainement_par_semaine = "5 à 7 fois par semaine" }

    var ideal_weight_reached;
    if (dataLead[0].ideal_weight_reached === "-1"){ ideal_weight_reached = "Moins de 1 an" }
    else if (dataLead[0].ideal_weight_reached === "1-3"){ ideal_weight_reached = "1-3 ans" }
    else if (dataLead[0].ideal_weight_reached === "+3"){ ideal_weight_reached = "Plus de 3 ans" }
    else if (dataLead[0].ideal_weight_reached === "jamais"){ ideal_weight_reached = "Je n'ai jamais atteint mon poids idéal" }
    
    var tried_lose_weight;
    if (dataLead[0].tried_lose_weight === "Jamais"){ tried_lose_weight = "Jamais" }
    else if (dataLead[0].tried_lose_weight === "1-2 fois"){ tried_lose_weight = "1-2 fois" }
    else if (dataLead[0].tried_lose_weight === "beaucoup de tentatives"){ tried_lose_weight = "J'ai eu beaucoup de tentatives" }
    
    var dormir;
    if (dataLead[0].dormir === "<5"){ dormir = "Moins de 5 heures" }
    else if (dataLead[0].dormir === "5-6"){ dormir = "5-6 heures" }
    else if (dataLead[0].dormir === "7-8"){ dormir = "7-8 heures" }
    else if (dataLead[0].dormir === ">8"){ dormir = "Plus de 8 heures" }

    var eau;
    if (dataLead[0].eau === "Café ou thé seulement"){ eau = "cafe-the" }
    else if (dataLead[0].eau === "<2-verres"){ eau = "Moins de 2 verres" }
    else if (dataLead[0].eau === "2-6-verres"){ eau = "2-6 verres" }
    else if (dataLead[0].eau === "7-10-verres"){ eau = "7-10 verres" }
    else if (dataLead[0].eau === ">10-verres"){ eau = "Plus de 10 verres" }

    var temppreprepas;
    if (dataLead[0].temppreprepas === "<30min"){ temppreprepas = "Moins de 30 minutes" }
    else if (dataLead[0].temppreprepas === "30-60min"){ temppreprepas = "30-60 minutes" }
    else if (dataLead[0].temppreprepas === ">1h"){ temppreprepas = "Plus d'une heure" }

    var alimentssurgeles;
    if (dataLead[0].alimentssurgeles === "Non"){ alimentssurgeles = "Non, Je préfère les plats cuisinés frais" }


    return (
        <>
        <Descriptions column={1}  title="Tous les jours" bordered extra={<Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />}>
          <Descriptions.Item label="Type de journée">{journeetype}</Descriptions.Item>
          <Descriptions.Item label="Ressentez-vous des maux d'estomac pendant la journée?">{dataLead[0].mauxestomac}</Descriptions.Item>
          <Descriptions.Item label="Depuis combien de temps n'avez-vous pas atteint votre poids idéal?">{ideal_weight_reached}</Descriptions.Item>
          <Descriptions.Item label="Combien de fois avez-vous essayé de perdre du poids?">{tried_lose_weight}</Descriptions.Item>
          <Descriptions.Item label="Nombre de repas par jour">{nrepas}</Descriptions.Item>
          <Descriptions.Item label="Nombre d'entrainements par semaine">{entrainement_par_semaine}</Descriptions.Item>
          <Descriptions.Item label="Comment est votre énergie au jour le jour?">{dataLead[0].energie}</Descriptions.Item>
          <Descriptions.Item label="Combien dormez-vous?">{dormir}</Descriptions.Item>
          <Descriptions.Item label="Combien d'eau buvez-vous quotidiennement?">{eau}</Descriptions.Item>
          <Descriptions.Item label="Combien de temps êtes-vous prêt à consacrer à la préparation d'un repas?">{temppreprepas}</Descriptions.Item>
          <Descriptions.Item label="Vous aimeriez inclure des aliments surgelés ou prêts à cuire dans votre plan de repas?">{alimentssurgeles}</Descriptions.Item>
        </Descriptions>
        <Modal
            title="Tous les jours"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadDaily dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
    )}
    export default LeadDaily;