import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import clientConfig from '../../../client-config';
import { Link } from 'react-router-dom';
import { Button, Input, Space, Table } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const PlansTable = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 110,
            }}
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchRecords({
      pagination,
    });
    
  }, []);

    const columns = [
      {
        title:'Email',
        dataIndex:'email',
        width: '30%',
        ...getColumnSearchProps('email'),
      },
      {
        title:'Perdre poids',
        dataIndex:'perdrepoids',
        width: '10%',
      },
      {
        title:'Sexe',
        dataIndex:'sexe',
        width: '10%',
      },
      {
        title:'Age',
        dataIndex:'age',
        sorter: (a, b) => a.age - b.age,
        sortDirections: ['descend', 'ascend'],
        width: '10%',
      },
      {
        title:'Taille',
        dataIndex:'taille',
        sorter: (a, b) => a.taille - b.taille,
        sortDirections: ['descend', 'ascend'],
        width: '10%',
      },
      {
        title:'Poids actuel',
        dataIndex:'poidsactuel',
        sorter: (a, b) => a.poidsactuel - b.poidsactuel,
        sortDirections: ['descend', 'ascend'],
        width: '20%',
      },
      {
        title: 'Voire plus',
        key: 'plus',
        render: (_, record) => (
          <Space size="middle">
            <Link to={{
                  pathname: `/lead/${record.Id}`,
                  state: {
                    Id: record.Id,
                }
              }}>
              <Button type="primary" icon={<PlusOutlined />} size="large" /></Link>
          </Space>
        ),
        width: '10%',
      },
    ]

    const fetchRecords = (params = {}) => {
      setLoading(true);
      const wordPressSiteUrl = clientConfig.siteUrl;
      axios
        .get(`${wordPressSiteUrl}/wp-json/leads/all`)
        .then((res) => {
          setDataSource(res.data);
          setTotalPages(res.data.totalPages);
          setLoading(false);
          setPagination({
            ...params.pagination,
            total: 200, // 200 is mock data, you should read it from server
            // total: data.totalCount,
          });
        });
    };

      return (
        
          <div>
            <Table 
              loading={loading}
              columns={columns}
              rowKey={record => record.Id}
              dataSource={dataSource}
              bordered
              pagination={{
                pageSize: 10,
                total: totalPages,
                onChange: (page) => {
                  fetchRecords(page);
              },
            }}>

             </Table>
          </div>
      )
  
}

export default PlansTable;