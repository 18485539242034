import React, { Component, Fragment } from 'react';
import axios from 'axios';
import clientConfig from '../../../client-config';
import { Link } from 'react-router-dom';
import { Spin, Col, Row, Divider, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
const { Title } = Typography;


export class PostPage extends Component {
    state = {
        post: {},
        isLoaded: false
    }
    componentDidMount() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios
        .get(`${wordPressSiteUrl}/wp-json/wp/v2/posts/${this.props.match.params.id}`)
            .then(res => this.setState({
                post: res.data,
                isLoaded: true
            }))
            .catch(err => console.log("error =: "+err));
    }
  render() {
    const {post, isLoaded } = this.state;
    if(isLoaded){
        return (
            <Fragment>
                <div className='main'>
                <div className="App">
                    <Row id="feature" className="block featureBlock">
                        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
                            <Link to="/blog" ><LeftOutlined /> Retounez</Link>
                            <Divider />
                            <Title level={2}>{post.title.rendered}</Title>
                            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
                        </Col>
                    </Row>
                </div>
            </div>
            </Fragment>
          )
    }
    return <div className='main'>
                <div className="App">
                    <Row id="feature" className="block featureBlock">
                        <Col xs={{ span: 24 }} >
                            <div className="container-fluid">
                                <div className="CenterInside"><Spin /></div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>;
  }
}

export default PostPage;