import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Checkbox, Form, Button, Typography, Row, Col, Divider, Switch } from 'antd';
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    vraisofaux: defaults
};
const EditLeadVraisofaux = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    const uvraisofaux ={
        Id: dataLead[0].Id,
        vraisofaux: dataLead[0].vraisofaux,
      };
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const onSwitch = () => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    const submitForm = (e) => {
        if (switched === true) {
            uvraisofaux.vraisofaux = JSON.stringify(defaults);
        }
        else if (switched === false){
            uvraisofaux.vraisofaux = JSON.stringify(e.vraisofaux);
        }
        onChange(e);
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/uvraisofaux`, uvraisofaux)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }
    return(
      <Form onFinish={submitForm} initialValues={initValues} >
        <Form.Item
          name="vraisofaux"
          value={uvraisofaux.vraisofaux}
          onChange={(e) => {uvraisofaux.vraisofaux = e.target.value }}
        >
          <CheckboxGroup
            className="boxe"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Checkbox value="Je mange tard le soir" disabled={disabled}><Text>Je mange tard le soir</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Je ne dors pas assez" disabled={disabled}><Text>Je ne dors pas assez</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Je ne peux pas renoncer à manger des sucreries" disabled={disabled}><Text>Je ne peux pas renoncer à manger des sucreries</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="J'aime les boissons gazeuses" disabled={disabled}><Text>J'aime les boissons gazeuses</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Je consomme beaucoup de sel" disabled={disabled}><Text>Je consomme beaucoup de sel</Text></Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        
        <Row justify="end">
          <Col xs={{ span: 9 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>
            <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
          </Col>
          <Col span={2}>
            <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
              <Switch checked={switched} onChange={onSwitch} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadVraisofaux;