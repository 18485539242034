import React from "react";
import { Typography } from 'antd';

const { Title } = Typography;

const AppDcn = ({ values }) => {
     //destructuring the object from values
      const { perdrepoids, sexe, age, taille, poidsactuel, journeetype } = values;
      var sex = sexe
      var weight = parseInt(poidsactuel)
      var Height = parseInt(taille)
      var old = parseInt(age)
      var pp = perdrepoids
      var jt = journeetype

    function basicmeta(sex) {
      if (sex === "Homme") {
        return parseInt((weight * 10) + (Height * 6.25) - (old * 5) + 5);
      }
      if (sex === "Femme") {
        return parseInt((weight * 10) + (Height * 6.25) - (old * 5) - 161);
      }
      return console.error();
    }
    function calorierequirement(jt) {
      if (jt === "Sédentaire") {
        return parseInt(basicmeta(sex)) * 1.2;
      }
      if (jt === "Faible Actif") {
        return parseInt(basicmeta(sex)) * 1.375;
      }
      if (jt === "Actif") {
        return parseInt(basicmeta(sex)) * 1.55;
      }
      if (jt === "Athlète") {
        return parseInt(basicmeta(sex)) * 1.725;
      }
      return console.error();
    }
    function objmeta(perdrepoids) {
      if (perdrepoids === "Oui") {
        return parseInt(parseInt(calorierequirement(jt))) - 200;
      }
      if (perdrepoids === "Non") {
        return parseInt(parseInt(calorierequirement(jt))) + 500;
      }
      return console.error();
    }
    
    return (
        <>
          <Title level={3}>{parseInt(objmeta(pp))} Kcal </Title>
        </>
    );
}
export default AppDcn;