import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import clientConfig from '../../client-config';
import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Typography, Alert } from 'antd';
const { Title } = Typography;

const ResetPassword = () => {
 const [param, setParam] = useState({
  email: '',
 });
 const [msg, setMsg] = useState('');
 const [error, setError] = useState('');
 const submitForm = () => {
  const wordPressSiteUrl = clientConfig.siteUrl;
  axios
   .post(`${wordPressSiteUrl}/wp-json/bdpwr/v1/reset-password`, param)
   .then((mssg) => {
    setMsg(mssg.data.data.status);
   })
   .catch((err) => {
    setError(err.response.data.data.status);
   });
 };
 useEffect(() => {
  if (msg === 200) {
   setError();
  }
 }, [msg, error]);
 return (
  <div className="main">
   <div className="App">
    <Row id="feature" className="block featureBlock">
     <Col
      xs={{ span: 22, offset: 1 }}
      sm={{ span: 18, offset: 3 }}
      md={{ span: 8, offset: 8 }}
     >
      <div className="container-fluid">
       <Title level={2}>Mot de passe oublié</Title>

       {error === 400 && (
        <Alert message="Vous devez fournir une adresse e-mail." type="error" />
       )}
       {error === 500 && (
        <Alert
         message="Aucun utilisateur trouvé avec cette adresse e-mail."
         type="error"
        />
       )}
       <br />
       <Form
        name="RestPassword"
        className="login-form"
        onFinish={submitForm}
        block
       >
        <Form.Item
         onChange={(e) => {
          setParam({ email: e.target.value });
         }}
         rules={[
          {
           required: true,
           message: 'Veuillez saisir votre adresse e-mail!',
          },
         ]}
        >
         <Input
          name="email"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Adresse email"
         />
        </Form.Item>
        <Form.Item>
         <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          block
         >
          Envoyer
         </Button>
        </Form.Item>
       </Form>
       {msg === 200 && (
        <>
         <Alert
          message="Un e-mail de réinitialisation du mot de passe a été envoyé à votre adresse e-mail. Cela peut prendre quelques minutes."
          type="info"
         />
         <br />
         <Link
          to={{
           pathname: '/login/setnewpassword',
           state: {
            mail: param.email,
           },
          }}
         >
          <Button type="primary" block>
           Définir un nouveau mot de passe
          </Button>
         </Link>
        </>
       )}
      </div>
     </Col>
    </Row>
   </div>
  </div>
 );
};

export default ResetPassword;
