import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Checkbox, Form, Button, Typography, Row, Col, Divider, Switch, Image } from 'antd';
import Riz from '../../../home/steps/img/Riz.svg';
import Couscous from '../../../home/steps/img/Couscous.svg'; import Quinoa from '../../../home/steps/img/Quinoa.svg';
import Sarrasin from '../../../home/steps/img/Sarrasin.svg'; import Amarante from '../../../home/steps/img/Amarante.svg';
import Semouledemaïs from '../../../home/steps/img/Semouledemaïs.svg'; import Grasdemillet from '../../../home/steps/img/Grasdemillet.svg';
import Boulgour from '../../../home/steps/img/Boulgour.svg'; import Semoule from '../../../home/steps/img/Semoule.svg';
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    cereales: defaults
};
const EditLeadCereales = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    const ucereales ={
        Id: dataLead[0].Id,
        cereales: dataLead[0].cereales,
      };
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const onSwitch = () => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    const submitForm = (e) => {
        if (switched === true) {
            ucereales.cereales = JSON.stringify(defaults);
        }
        else if (switched === false){
            ucereales.cereales = JSON.stringify(e.cereales);
        }
        onChange(e);
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/ucereales`, ucereales)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }
    return(
      <Form onFinish={submitForm} initialValues={initValues} >
        <Form.Item
          name="cereales"
          value={ucereales.cereales}
          onChange={(e) => {ucereales.cereales = e.target.value }}
        >
          <CheckboxGroup
            className="boxe"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
            <Col span={24}>
              <Checkbox value="Riz" disabled={disabled}><Text><Image width={18} src={Riz} preview={false}/> Riz</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Quinoa" disabled={disabled}><Text><Image width={18} src={Quinoa} preview={false}/> Quinoa</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Couscous" disabled={disabled}><Text><Image width={18} src={Couscous} preview={false}/> Couscous</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Sarrasin" disabled={disabled}><Text><Image width={18} src={Sarrasin} preview={false}/> Sarrasin</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Amarante" disabled={disabled}><Text><Image width={18} src={Amarante} preview={false}/> Amarante</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Semoule de maïs" disabled={disabled}><Text><Image width={18} src={Semouledemaïs} preview={false}/> Semoule de maïs</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Gras de millet" disabled={disabled}><Text><Image width={18} src={Grasdemillet} preview={false}/> Gras de millet</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Boulgour" disabled={disabled}><Text><Image width={18} src={Boulgour} preview={false}/> Boulgour</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="La semoule" disabled={disabled}><Text><Image width={18} src={Semoule} preview={false}/> La semoule</Text></Checkbox>
            </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        
        <Row justify="end">
          <Col xs={{ span: 9 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>
            <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
          </Col>
          <Col span={2}>
            <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
              <Switch checked={switched} onChange={onSwitch} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadCereales;