import React, { Component } from 'react';
import axios from "axios";
import { Redirect, Link } from 'react-router-dom';
import clientConfig from '../../client-config';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Alert  } from 'antd';

const initialState = {
    Id: '',
    username: '',
    password: '',
    userNiceName: '',
    userEmail: '',
    role: '',
    loggedIn: false,
    loading: false,
    error: '',
    failed: false,
    lead: ''
};
export class Login extends Component {
    constructor(props){
        super(props);
        this.state = initialState
    }
    _isMounted = false
    LoginFunction = () => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const loginData = {
            username: this.state.username,
            password: this.state.password,
        }
        axios.post(`${wordPressSiteUrl}/wp-json/jwt-auth/v1/token`, loginData)
          .then(res => {
            if ( undefined === res.data.token ){
              this.setState( { error: res.data.message, loading: false } );
              return;
            }
            if(this._isMounted){
                localStorage.setItem( 'token', res.data.token);
                localStorage.setItem( 'userName', res.data.user_nicename);
                localStorage.setItem( 'userEmail', res.data.user_email);
                this.setState({
                  token: res.data.token,
                  userNiceName: res.data.user_nicename,
                  userEmail: res.data.user_email,
              })
              this.getRole(res.data.user_email)
            }
        })
        .catch( err => {
          if (!err?.res){
            this.setState( { error: 'Identifiant ou mot de passe incorrect', loading: false, loggedIn: false, failed: true } );
          } else if (err.res?.status === 400){
            this.setState( { error: 'Identifiant ou mot de passe manquant', loading: false, loggedIn:false, failed: true } );
          } else if (err.response?.status === 401){
            this.setState( { error: 'Non autorisé', loading: false, loggedIn:false, failed: true } );
          } else {
            this.setState( { error: 'Échec de la connexion', loading: false, loggedIn:false, failed: true } );
          }
        })
    }
    getRole = (mail) => {
        if(this._isMounted){
            const wordPressSiteUrl = clientConfig.siteUrl;
            axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/users/?search=${mail}`)
            .then(resp => {
                localStorage.setItem( 'id', resp.data[0].id);
                localStorage.setItem( 'role', resp.data[0].roles[0]);
                if(resp.data[0].roles[0]==="subscriber"){
                    this.getLeadId(mail)
                    this.setState({ 
                        Id:  resp.data[0].id,
                        role: resp.data[0].roles[0]
                    })
                }else {
                    localStorage.setItem( 'loggedIn', true);
                    this.setState({
                        loading: true,
                        loggedIn: true,
                        Id:  resp.data[0].id,
                        role: resp.data[0].roles[0]
                    })
                }
            });
        }
    }
    getLeadId = (mail)=> {
        if(this._isMounted){
            const wordPressSiteUrl = clientConfig.siteUrl;
            axios.get(`${wordPressSiteUrl}/wp-json/leads/mail/${mail}`)
            .then(respm=>{
                localStorage.setItem( 'lead', respm.data[0].Id);
                localStorage.setItem( 'loggedIn', true);
                this.setState({
                    loading: true,
                    loggedIn: true,
                    lead:  respm.data[0].Id,
                })
            })

        }
    }
    onFormSubmit = () => {
        this.LoginFunction()
    };
    handleOnChange = (e) => {
        this.setState({[e.target.name] : e.target.value })
    }
    componentDidMount(){
        this._isMounted = true
    }
    componentWillUnmount(){
        this._isMounted = false
    }
    renderElement(){
        if(this.state.failed === true)
           return <Alert message={this.state.error} type="error" />;
        return null;
     }
    render() {
      const { username, password, loggedIn, userNiceName, role, lead } = this.state;
      const user = ( userNiceName ) ? userNiceName : localStorage.getItem( 'userName' );
      const Lead = ( lead ) ? lead : localStorage.getItem( 'lead' );
    if ( loggedIn ) {
        if (role === 'administrator' || role === 'editor'){
            return ( <Redirect to={`/dashboard/${user}`} noThrow /> );
        } 
        if (role === 'subscriber'){
            return ( <Redirect to={{
                pathname: `/leadus/${Lead}`,
                state: {
                    Id: Lead
                }
            }} /> );
        }
    } else {

    return (
      <div className='main'>
        <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }} >
               <div className="container-fluid">
               { this.renderElement() }
               <br />
                <Form
                      name="Login"
                      className="login-form"
                      initialValues={{ remember: true, }}
                      onFinish={this.onFormSubmit}
                >
                  <Form.Item
                      onChange={this.handleOnChange}
                      rules={[
                      {
                        required: true,
                        message: 'Veuillez saisir votre nom d\'utilisateur ou votre adresse e-mail!',
                      },
                      ]}
                  >
                    <Input 
                      name="username"
                      value={username}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Adresse email ou nom d'utilisateur" />
                  </Form.Item>
                  <Form.Item
                      onChange={this.handleOnChange}
                      rules={[
                      {
                        required: true,
                        message: 'Veuillez saisir votre mot de passe!',
                      },
                      ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      name="password"
                      value={password}
                      type="password"
                      placeholder="Mot de passe"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                    Se connecter
                    </Button> Ou <Link to="/login/resetpassword">Mot de passe oublié</Link>
                  </Form.Item>
                  <Form.Item>
                    <a href="/">Inscrivez-vous maintenant en remplissant le formulaire!</a>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
    }
  }
}

export default Login;