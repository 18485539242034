import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const AppStep15 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
          await delay(500);
        next();
    };
    return(
        <Form 
            name="step15"
            onChange={submitFormData}
        >
            <Title level={2}>Comment est votre énergie au jour le jour ?</Title>
            <Form.Item 
                name="energie"
                defaultValue={values.energie}
                onChange={handleFormData("energie")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="Constant" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Constant</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="Trainer avent les repas" />
                        <label htmlFor="control_02">
                                <Text strong>Traîner avant les repas</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="Effondrement après le déjeuner" />
                        <label htmlFor="control_03">
                                <Text strong>Effondrement après le déjeuner</Text>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep15;