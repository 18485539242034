import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Progress, Button, Space, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const AppImc = ({ values }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { taille, poidsactuel } = values;
  var weight = parseInt(poidsactuel)
  var height = parseInt(taille)
    const [Status, setStatus] = useState("");
    const [Message, setMessage] = useState("");
    const [Effects, setEffects] = useState("");
    const [imcMessage, setImcMessage] = useState("");

    const [views, setViews] = useState(0);
    const [isInitialRender, setIsInitialRender] = useState(true);
  
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      calcularIMC ();
      if (isInitialRender) {
        setIsInitialRender(false);
        setViews(views + 1);
      }
    }, [views, isInitialRender]);
   

  function calcularIMC (){
    const alt = height / 100;
    const imc = weight / (alt * alt);

    if (imc < 16.9){
        setStatus(<Progress percent={36} showInfo={false} status="exception"/>)
        setMessage(`Vous êtes bien en dessous du poids !`);
        setEffects(`Effets secondaires : Perte de cheveux, infertilité, absence menstruelle.`);
        setImcMessage(`${imc.toFixed(2)}`);
     
    }else if(imc >= 17 && imc < 18.4){
        setStatus(<Progress percent={46} showInfo={false} status="exception"/>)
        setMessage(`Vous êtes en sous-poids !`);
        setEffects(`Effets secondaires : Fatigue, stress, anxiété.`);
        setImcMessage(`${imc.toFixed(2)}`);
     
    }else if (imc >= 18.5 && imc < 24.9){
        setStatus(<Progress percent={60} showInfo={false} status="success"/>)
        setMessage(`Vous êtes à un poids normal !`);
        setEffects(`Effets secondaires : Diminution du risque de maladies cardiaques et vasculaires.`);
        setImcMessage(`${imc.toFixed(2)}`);
    
    }else if(imc >= 25 && imc < 29.9){
        setStatus(<Progress percent={70} showInfo={false} status="exception"/>)
        setMessage(`Vous êtes en surpoids!`);
        setEffects(`Effets secondaires : Diminution du risque de maladies cardiaques et vasculaires.`);
        setImcMessage(`${imc.toFixed(2)}`);
     
    }else if(imc >= 30 && imc < 34.9){
        setStatus(<Progress percent={78} showInfo={false} status="exception"/>)
        setMessage(`Vous êtes en obésité de grade II ! `);
        setEffects(`Effets secondaires : Apnée du sommeil, essoufflement.`);
        setImcMessage(`${imc.toFixed(2)}`);

    }else if(imc >= 35 && imc < 40){
        setStatus(<Progress percent={95} showInfo={false} status="exception"/>)
        setMessage(`Vous êtes en obésité de grade II ! `);
        setEffects(`Effets secondaires : Diabète, angine de poitrine, crise cardiaque, athérosclérose`);
        setImcMessage(`${imc.toFixed(2)}`);
    }else if(imc >= 40){
        setStatus(<Progress percent={100} showInfo={false} status="exception"/>)
        setMessage(`Vous êtes en obésité de grade III ! `);
        setEffects(`Effets secondaires : Reflux, difficulté à bouger, escarres, diabète, crise cardiaque, accident vasculaire cérébral.`);
        setImcMessage(`${imc.toFixed(2)}`);
    }
  }

  return(
    <>
      <Row gutter={20}>
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 16}}>
          <Title level={3}>IMC actuel</Title>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8}}>
          <Space wrap>
            <Button type="link" onClick={showModal} icon={<QuestionCircleOutlined />} className="imcbtn">C'est quoi l'IMC</Button>
          </Space>
        </Col>
        <Col span={24}>
          <Title level={3}>{imcMessage}</Title>
        </Col>
        <Col span={24} className="center">
          <Text strong>{Message}</Text>
        </Col>
        <Col span={24}>
        {Status}
        </Col>
        <Col span={24}>
          <Text>{Effects}</Text>
        </Col>
      </Row>

      <Modal title="BMI" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} width={'100%'}>
        <Typography>
          <Paragraph>
          <Text strong>L'indice de masse corporelle (IMC)</Text>est une valeur dérivée de la masse et de la taille d'une personne.
          </Paragraph>
          <Paragraph>
          L'IMC est une règle empirique pratique utilisée pour catégoriser une personne comme ayant un poids insuffisant, normal, légèrement en surpoids ou obèse.
          </Paragraph>
        </Typography>
        <Row gutter={[0, 8]}>
          <Col span={12} className="imc imcunderweightnum">
            <center><Text strong>&lt; 18,5</Text></center>
          </Col>
          <Col span={12} className="imcunderweighttxt">
            <Text strong>SOUS-POIDS</Text>
          </Col>
          <Col span={12} className="imc imcnormalnum">
            <center><Text strong>18,5-24,9</Text></center>
          </Col>
          <Col span={12} className="imcnormaltxt">
            <Text strong>NORMAL</Text>
          </Col>
          <Col span={12} className="imc imcsurpoidsnum">
            <center><Text strong>25-34,9</Text></center>
          </Col>
          <Col span={12} className="imcsurpoidstxt">
            <Text strong>EN SURPOIDS</Text>
          </Col>
          <Col span={12} className="imc imcobesenum">
            <center><Text strong>&gt; 35</Text></center>
          </Col>
          <Col span={12} className="imcobesetxt">
            <Text strong>OBÈSE</Text>
          </Col>
        </Row>
      </Modal>
    </>
  
  );
}

export default AppImc;