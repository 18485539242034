import React, {useState}  from "react";
import { Form, Row, Col, Typography, Button, Space, Image, Checkbox, Switch } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import Avocado from './img/Avocado.svg'; import Haricots from './img/Haricots.svg';
import Champignons from './img/Champignons.svg'; import Oeufs from './img/Oeufs.svg';
import Lait from './img/Lait.svg'; import Fromageblanc from './img/Fromageblanc.svg';
import Tofu from './img/Tofu.svg'; import Hoummous from './img/Hoummous.svg';
import Laitvegetal from './img/Laitvegetal.svg';
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    produits: defaults
};
const AppStep24 = ({ next, prev, handleFormData, values }) => {
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const submitFormData = (e) => {
        if (switched === true) {
            values.produits = JSON.stringify(defaults);
        }
        else if (switched === false){
            values.produits = JSON.stringify(e.produits);
        }
        onChange(e);
        next();
    }
    const onSwitch = (e) => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    return(
        <Form 
            name="step24"
            onFinish={submitFormData}
            initialValues={initValues}
        >
            <Title level={2}>Cochez les produits que vous souhaitez inclure :</Title>
            <Form.Item
                    name="produits"
                    checkedvalues={values.produits}
                    onChange={handleFormData("produits")}
                >
            <CheckboxGroup 
                 className="boxe"
                 style={{ width: '100%' }}
                 onChange={onChange}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Checkbox value="Avocado" disabled={disabled}><Text><Image width={18} src={Avocado} preview={false}/> Avocado</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Haricots" disabled={disabled}><Text><Image width={18} src={Haricots} preview={false}/> Haricots</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Champignons" disabled={disabled}><Text><Image width={18} src={Champignons} preview={false}/> Champignons</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Des œufs" disabled={disabled}><Text><Image width={18} src={Oeufs} preview={false}/> Des œufs</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Du lait" disabled={disabled}><Text><Image width={18} src={Lait} preview={false}/> Du lait</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Fromage blanc" disabled={disabled}><Text><Image width={18} src={Fromageblanc} preview={false}/> Fromage blanc</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Tofu" disabled={disabled}><Text><Image width={18} src={Tofu} preview={false}/> Tofu</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Hoummous" disabled={disabled}><Text><Image width={18} src={Hoummous} preview={false}/> Hoummous</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Lait végétal" disabled={disabled}><Text><Image width={18} src={Laitvegetal} preview={false}/> Lait végétal</Text></Checkbox>
                        </Col>
                    </Row>
                  </CheckboxGroup>
                </Form.Item>
                <Row justify="end">
                    <Col xs={{ span: 10 }} sm={{ span: 11 }} md={{ span: 11 }} lg={{ span: 9 }} xl={{ span: 7 }}>
                        <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
                    </Col>
                    <Col xs={{ span: 2 }} sm={{ span: 1 }} md={{ span: 1 }}>
                        <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
                            <Switch checked={switched} onChange={onSwitch} />
                        </Form.Item>
                    </Col>
              </Row>
                <br />
        
            <Row justify="end">
                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                    <Form.Item  className="steps-action">
                    <Space style={{ width: '100%' }}>
                        <Button onClick={prev} icon={<CaretLeftOutlined />} />
                        <Button type="primary" htmlType="submit">
                            Continue
                        </Button>
                    </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
export default AppStep24;