import React, { useState } from 'react';
import EditLeadAppareils from '../leadedit/editleadappareils';
import { List, Typography, Button, Col, Row, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';
const { Title } = Typography;

const LeadAppareils = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    let appareils = JSON.parse(dataLead[0].appareils.split());
    return (
        <>
        <List
          size="small"
          header={
          <Row gutter={[24, 24]}>
              <Col span={21}>
                <Title level={5}>Les appareils de cuisine que vous avez :</Title>
              </Col>
              <Col span={3}>
                <Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />
              </Col>
            </Row>}
          bordered
          dataSource={appareils}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />

        <Modal
            title="Quels appareils de cuisine avez-vous ?"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadAppareils dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
        );
    }
    export default LeadAppareils;