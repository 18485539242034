import React from 'react';
import Iframe from 'react-iframe';
import clientConfig from '../../../client-config';

const HomeIframe = () => {
    const wordPressSiteUrl = clientConfig.siteUrl;

  return (
        <Iframe url={wordPressSiteUrl}
                width="100%"
                height="100%"
                id=""
                className="block"
                scrolling="not"
                overflow= "hidden"
                position="absolute"
                allow="fullscreen"
                />
  )
}
export default HomeIframe;