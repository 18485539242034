const clientConfig = {
   /*  siteUrl: 'http://localhost/meal/',
    siteToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0L21lYWwiLCJpYXQiOjE2Nzc1MjEwNDksIm5iZiI6MTY3NzUyMTA0OSwiZXhwIjoxNzA5MDU3MDQ5LCJkYXRhIjp7InVzZXIiOnsiaWQiOiIxIn19fQ.-UVOi6ySQHfVOhG613yj4Bo5bjjZ8oTBjfy7OeB76hU'
   */
  /*   siteUrl: 'https://www.mealapp.grwv1900.odns.fr/back',
    siteToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3d3Lm5leHRiZWRlc2lnbi5jb21cL21lYWx3cCIsImlhdCI6MTY3MTU4MzYwNSwibmJmIjoxNjcxNTgzNjA1LCJleHAiOjE2NzIxODg0MDUsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.Z82bmSX63_rH8VpZEoYIzd2-ZaOt3czbL8K9o5AaNB0'
 */
    siteUrl: 'https://nextbedesign.com/back',
    siteToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL25leHRiZWRlc2lnbi5jb20vYmFjayIsImlhdCI6MTY4MzkwNTMyOCwibmJmIjoxNjgzOTA1MzI4LCJleHAiOjE2ODQ1MTAxMjgsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.a1UFfpsLzg3rkYN18dkxAiBJroOJ1C8yE0PXRqTzsKA',
    username: 'Redouan',
    password: 'I6am6nobody'
  }

export default clientConfig;