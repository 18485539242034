import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../client-config';
import { Row, Col, Card, Statistic, Typography } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
const { Title } = Typography;

const LeadsNumber = () => {
    const [datalength, setDatalength] = useState([]);
    const [datadatelength, setDataDatelength] = useState([]);
    const wordPressSiteUrl = clientConfig.siteUrl;
    axios.get(`${wordPressSiteUrl}/wp-json/leads/all`)
      .then((res) => {setDatalength(res.data.length)})
      .catch(err => {console.log(err.response.data.message)})

    axios.get(`${wordPressSiteUrl}/wp-json/leads/all/datetime`)
      .then((resp) => {setDataDatelength(resp.data.length)})
      .catch(err => {console.log(err.response.data.message)})

    const prcent = (datadatelength / datalength)*100;
    
 return (
  <>
    <Card bordered={false} className="gx-card gx-card-primary">
      <div className="number">
        <Row align="middle">
          <Col xs={24}>
            <Title level={4} className='gx-title4'>Leads</Title>
          </Col>
          <Col xs={14}>
            <Title level={3} className='gx-title2'>{datalength}</Title>
          </Col>
          <Col xs={10}>
            <Statistic
              value={prcent}
              precision={1}
              valueStyle={{
              color:'#3f8600'
              }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Col>
        </Row>
      </div>
    </Card>
  </>
 ); 
}

export default LeadsNumber;
