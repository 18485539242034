import React, {useState} from "react";
import { Form, Row, Col, Typography, Button, Space, Image, Checkbox, Switch } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import Riz from './img/Riz.svg';
import Couscous from './img/Couscous.svg'; import Quinoa from './img/Quinoa.svg';
import Sarrasin from './img/Sarrasin.svg'; import Amarante from './img/Amarante.svg';
import Semouledemaïs from './img/Semouledemaïs.svg'; import Grasdemillet from './img/Grasdemillet.svg';
import Boulgour from './img/Boulgour.svg'; import Semoule from './img/Semoule.svg';
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    cereales: defaults
};
const AppStep23 = ({ next, prev, handleFormData, values }) => {
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const submitFormData = (e) => {
        if (switched === true) {
            values.cereales = JSON.stringify(defaults);
        }
        else if (switched === false){
            values.cereales = JSON.stringify(e.cereales);
        }
        onChange(e);
        next();
    }
    const onSwitch = (e) => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }

    return(
        <Form 
            name="step23"
            onFinish={submitFormData}
            initialValues={initValues}
        >
            <Title level={2}>Cochez les céréales que vous souhaitez inclure :</Title>
            <Form.Item
                    name="cereales"
                    checkedvalues={values.cereales}
                    onChange={handleFormData("cereales")}
                >
                <CheckboxGroup 
                   className="boxe"
                   style={{ width: '100%' }}
                   onChange={onChange}
                >

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Checkbox value="Riz" disabled={disabled}><Text><Image width={18} src={Riz} preview={false}/> Riz</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Quinoa" disabled={disabled}><Text><Image width={18} src={Quinoa} preview={false}/> Quinoa</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Couscous" disabled={disabled}><Text><Image width={18} src={Couscous} preview={false}/> Couscous</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Sarrasin" disabled={disabled}><Text><Image width={18} src={Sarrasin} preview={false}/> Sarrasin</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Amarante" disabled={disabled}><Text><Image width={18} src={Amarante} preview={false}/> Amarante</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Semoule de maïs" disabled={disabled}><Text><Image width={18} src={Semouledemaïs} preview={false}/> Semoule de maïs</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Gras de millet" disabled={disabled}><Text><Image width={18} src={Grasdemillet} preview={false}/> Gras de millet</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Boulgour" disabled={disabled}><Text><Image width={18} src={Boulgour} preview={false}/> Boulgour</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="La semoule" disabled={disabled}><Text><Image width={18} src={Semoule} preview={false}/> La semoule</Text></Checkbox>
                        </Col>
                    </Row>
                </CheckboxGroup>
            </Form.Item>
            <Row justify="end">
                <Col xs={{ span: 10 }} sm={{ span: 11 }} md={{ span: 11 }} lg={{ span: 9 }} xl={{ span: 7 }}>
                    <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
                </Col>
                <Col xs={{ span: 2 }} sm={{ span: 1 }} md={{ span: 1 }}>
                    <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
                        <Switch checked={switched} onChange={onSwitch} />
                    </Form.Item>
                </Col>
              </Row>
                <br />
        
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep23;