import React from "react";
import { Form, InputNumber, Row, Col, Typography, Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { number } from "prop-types";

const { Title } = Typography;


const AppStep4 = ({ next, prev, handleFormData, values }) => {
    const submitFormData = (e) => {
        next();
      };

    return(
        <Form 
        name="step4"
        onFinish={submitFormData}
        labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
                <Title level={2}>Allons vérifier les mensurations de votre corps</Title>
                <br />
                <Form.Item 
                    label="Age"
                    name="age"
                    defaultValue={values.age}
                    onChange={handleFormData("age")}
                    rules={[
                        {
                        required: true,
                        message: 'Entrez votre age s\'il vous plaît!',
                        },
                ]}>
                    <InputNumber type="number" precision={number} size='large' addonAfter='ans' placeholder='00' min={12} max={100} style={{width: '100%',}} />
                </Form.Item>
                <Form.Item 
                    label="Taille"
                    name="taille"
                    defaultValue={values.taille}
                    onChange={handleFormData("taille")}
                    rules={[
                        {
                        required: true,
                        message: 'Entrez votre Taille s\'il vous plaît!',
                        },
                ]}>
                    <InputNumber type="number" precision={number} size='large' addonAfter='cm' placeholder='00' min={120} max={220} style={{width: '100%',}} />
                </Form.Item>
                <Form.Item 
                    label="Poids actuel"
                    name="poidsactuel"
                    defaultValue={values.poidsactuel}
                    onChange={handleFormData("poidsactuel")}
                    rules={[
                        {
                        required: true,
                        message: 'Entrez votre Poids actuel s\'il vous plaît!',
                        },
                ]}>
                  <InputNumber type="number" precision={number} size='large' addonAfter='kg' placeholder='00' min={40} max={250} style={{width: '100%',}} />
                </Form.Item>
                <Form.Item
                    label="Poids désiré"
                    name="poidssouhaite"
                    defaultValue={values.poidssouhaite}
                    onChange={handleFormData("poidssouhaite")}
                    rules={[
                    {
                        required: true,
                        message: 'Entrez votre poids souhaité s\'il vous plaît!',
                    },
                    ]}>
                  <InputNumber type="number" precision={number} size="large" addonAfter='kg' placeholder='00' min={40} max={250} style={{width: '100%',}}/>
                </Form.Item>
            <Row justify="end">
                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                    <Form.Item  className="steps-action">
                    <Space style={{ width: '100%' }}>
                        <Button type="default" size="large" shape="circle" onClick={prev} icon={<ArrowLeftOutlined />} />
                        <Button type="primary" size="large" shape="round" htmlType="submit">
                            Continue
                        </Button>
                    </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default AppStep4;