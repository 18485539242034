import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Checkbox, Form, Button, Typography, Row, Col, Divider, Switch, Image } from 'antd';
import Brocoli from '../../../home/steps/img/Broccoli.svg'; import Cauliflower from '../../../home/steps/img/Cauliflower.svg';
import Bellpepper from '../../../home/steps/img/Bellpepper.svg'; import Eggplant from '../../../home/steps/img/Eggplant.svg';
import Cabbage from '../../../home/steps/img/Cabbage.svg'; import Asparagus from '../../../home/steps/img/Asparagus.svg';
import Onion from '../../../home/steps/img/Onion.svg'; import Spinach from '../../../home/steps/img/Spinach.svg';
import Tomato from '../../../home/steps/img/Tomato.png'; import Cucumber from '../../../home/steps/img/Cucumber.png';

const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    legumes: defaults
};
const EditLeadLegumes = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    const ulegumes ={
        Id: dataLead[0].Id,
        legumes: dataLead[0].legumes,
      };
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const onSwitch = () => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    const submitForm = (e) => {
        if (switched === true) {
            ulegumes.legumes = JSON.stringify(defaults);
        }
        else if (switched === false){
            ulegumes.legumes = JSON.stringify(e.legumes);
        }
        onChange(e);
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/ulegumes`, ulegumes)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }
    return(
      <Form onFinish={submitForm} initialValues={initValues} >
        <Form.Item
          name="legumes"
          value={ulegumes.legumes}
          onChange={(e) => {ulegumes.legumes = e.target.value }}
        >
          <CheckboxGroup
            className="boxe"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
            <Col span={24}>
              <Checkbox value="Brocoli" disabled={disabled}><Image width={18} src={Brocoli} preview={false}/> <Text>Brocoli</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Choufleur" disabled={disabled}><Image width={18} src={Cauliflower} preview={false}/> <Text>Chou-fleur</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Poivron" disabled={disabled}><Image width={18} src={Bellpepper} preview={false}/> <Text>Poivron</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Aubergine" disabled={disabled}><Image width={18} src={Eggplant} preview={false}/> <Text>Aubergine</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Chou" disabled={disabled}><Text><Image width={18} src={Cabbage} preview={false}/> Chou</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Asperges" disabled={disabled}><Image width={18} src={Asparagus} preview={false}/> <Text>Asperges</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Epinard" disabled={disabled}><Image width={18} src={Spinach} preview={false}/> <Text>Epinard</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Oignon" disabled={disabled}><Image width={18} src={Onion} preview={false}/> <Text>Oignon</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Tomate" disabled={disabled}><Image width={18} src={Tomato} preview={false}/> <Text>Tomate</Text></Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Concombre" disabled={disabled}><Image width={18} src={Cucumber} preview={false}/> <Text>Concombre</Text></Checkbox>
            </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        
        <Row justify="end">
          <Col xs={{ span: 9 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>
            <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
          </Col>
          <Col span={2}>
            <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
              <Switch checked={switched} onChange={onSwitch} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadLegumes;