import React, { useState } from 'react';
import { Form, Row, Col, Typography, Radio, Checkbox, Button } from 'antd';
import 'antd/dist/antd.css';
const { Title } = Typography;

const AppStep1 = ({ next, handleFormData, values }) => {
    const [updates, setUpdates] = useState(false);
    values.updates = updates;
    const submitFormData = () => {
        next();
      };
        if (updates === true){
            values.updates = "Oui"
        }
        else{
            values.updates = "Non"
        }

    return(
    <Form 
        name="step1"
        onFinish={submitFormData}
      >
        <Title level={2}>Voulez vous perdre du poids?</Title>
            <Form.Item 
                name="perdrepoids"
                defaultValue={values.perdrepoids}
                onChange={handleFormData("perdrepoids")}
                className="center"
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <Radio.Group buttonStyle="solid" >
                    <Row gutter={[24, 24]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} >
                        <Radio.Button id="yeslose" value="Oui" htmlFor="yeslose" className="radiobutn" >Oui</Radio.Button>
                        <label className='lose-cc yeslose bgblue' htmlFor="yeslose"></label>   
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} >
                        <Radio.Button id="nolose" value="Non" htmlFor="nolose" className="radiobutn" >Non</Radio.Button>
                        <label className="lose-cc nolose bgred" htmlFor="nolose"></label>
                    </Col>
                    </Row>
                </Radio.Group>
            </Form.Item>
        
            <Row gutter={[24, 8]} justify="start">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                        name="conditions"
                        valuePropName="checked"
                        rules={[ {required: true, message: 'Pour continuer, veuillez accepter nos termes et politiques', }, ]}
                    >
                        <Checkbox>J'accepte les conditions d'utilisation, la politique de confidentialité, la politique de remboursement, les conditions d'abonnement, la politique en matière de cookies</Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                        name="updates"
                        valuePropName="checked"
                        onChange={() => setUpdates(!updates)}
                    >
                        <Checkbox value="Non">Je souhaite recevoir des mises à jour sur les produits, services et offres spéciales d'Unimeal par e-mail</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end">
                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Continue
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default AppStep1;