import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const AppStep19 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
          await delay(500);
        next();
    };
    return(
        <Form 
            name="step19"
            onChange={submitFormData}
        >
            <Title level={2}>Vous aimeriez inclure des aliments surgelés ou prêts à cuire dans votre plan de repas?</Title>
            <Title level={5}>Cette option peut vous faire gagner du temps sur la cuisson.</Title>
            <Form.Item 
                name="alimentssurgeles"
                defaultValue={values.alimentssurgeles}
                onChange={handleFormData("alimentssurgeles")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="Oui" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Oui</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="Non" />
                        <label htmlFor="control_02">
                                <Text strong>Non, Je préfère les plats cuisinés frais</Text>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="strat">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    {/* <Button type="primary" htmlType="submit">
                        Continue
                    </Button> */}
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep19;