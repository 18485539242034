import React, {useEffect, useState} from 'react';
import { Col, Row, Typography, TreeSelect, Form, Button, message } from 'antd';
import { useLocation } from 'react-router-dom';
import Foods from '../../../../assets/food2.json';
import axios from "axios";
import clientConfig from '../../../../client-config';
const { Title } = Typography;
const { TreeNode } = TreeSelect;

const MakeProgramItem = (RepasDay) => {
    const location = useLocation();
    const { Id, Nr } = location.state;
    const wordPressSiteUrl = clientConfig.siteUrl;
    const authToken = clientConfig.siteToken;
    const [proteinrp1, setProteinRp1] = useState(0); const [proteinrp2, setProteinRp2] = useState(0); const [proteinrp3, setProteinRp3] = useState(0); const [proteinrp4, setProteinRp4] = useState(0); const [proteinrp5, setProteinRp5] = useState(0);
    let protein = proteinrp1 + proteinrp2 + proteinrp3 + proteinrp4 + proteinrp5;
    const [lipiderp1, setLipideRp1] = useState(0); const [lipiderp2, setLipideRp2] = useState(0); const [lipiderp3, setLipideRp3] = useState(0); const [lipiderp4, setLipideRp4] = useState(0); const [lipiderp5, setLipideRp5] = useState(0);
    let lipide = lipiderp1 + lipiderp2 + lipiderp3 + lipiderp4 +lipiderp5;
    const [gluciderp1, setGlucideRp1] = useState(0); const [gluciderp2, setGlucideRp2] = useState(0); const [gluciderp3, setGlucideRp3] = useState(0); const [gluciderp4, setGlucideRp4] = useState(0); const [gluciderp5, setGlucideRp5] = useState(0);
    let glucide = gluciderp1 + gluciderp2 + gluciderp3 + gluciderp4 + gluciderp5;
    const [caloriesrp1, setCaloriesRp1] = useState(0); const [caloriesrp2, setCaloriesRp2] = useState(0); const [caloriesrp3, setCaloriesRp3] = useState(0); const [caloriesrp4, setCaloriesRp4] = useState(0); const [caloriesrp5, setCaloriesRp5] = useState(0);
    let calories = caloriesrp1 + caloriesrp2 + caloriesrp3 + caloriesrp4 + caloriesrp5;
    const [rp1, setRp1] = useState(); const [rp2, setRp2] = useState(); const [rp3, setRp3] = useState(); const [rp4, setRp4] = useState(); const [rp5, setRp5] = useState();
    const [rpArray, setRpArray] = useState([]);
    let rp = [];
    const [IdProg, setIdProg] = useState();
    const [repasexist, SetRepasExist] = useState(false)
    const onChangerp1 = (rp1) => {setRp1(rp1); setProteinRp1(Foods[rp1].protein); setLipideRp1(Foods[rp1].lipide); setGlucideRp1(Foods[rp1].glucide); setCaloriesRp1(Foods[rp1].calories); rpArray.splice(0, 1, rp1); setRpArray(rpArray)};
    const onChangerp2 = (rp2) => {setRp2(rp2); setProteinRp2(Foods[rp2].protein); setLipideRp2(Foods[rp2].lipide); setGlucideRp2(Foods[rp2].glucide); setCaloriesRp2(Foods[rp2].calories); rpArray.splice(1, 1, rp2); setRpArray(rpArray)};
    const onChangerp3 = (rp3) => {setRp3(rp3); setProteinRp3(Foods[rp3].protein); setLipideRp3(Foods[rp3].lipide); setGlucideRp3(Foods[rp3].glucide); setCaloriesRp3(Foods[rp3].calories); rpArray.splice(2, 1, rp3); setRpArray(rpArray)};
    const onChangerp4 = (rp4) => {setRp4(rp4); setProteinRp4(Foods[rp4].protein); setLipideRp4(Foods[rp4].lipide); setGlucideRp4(Foods[rp4].glucide); setCaloriesRp4(Foods[rp4].calories); rpArray.splice(3, 1, rp4); setRpArray(rpArray)};
    const onChangerp5 = (rp5) => {setRp5(rp5); setProteinRp5(Foods[rp5].protein); setLipideRp5(Foods[rp5].lipide); setGlucideRp5(Foods[rp5].glucide); setCaloriesRp5(Foods[rp5].calories); rpArray.splice(4, 1, rp5); setRpArray(rpArray)};
    var date = new Date()
    date.setDate(date.getDate() + RepasDay.RepasDay)
    function formatDate(date = new Date()) {
      const year = date.toLocaleString('default', {year: 'numeric'});
      const month = date.toLocaleString('default', {month: '2-digit'});
      const day = date.toLocaleString('default', {day: '2-digit'});
    
      return [year, month, day].join('-');
    }
    const warning = () => {
      message.warning({content: 'Veuillez entrer les repas',style: {marginTop: '20vh'}});
    };
    const RepasExist = () => {
      axios
        .get(`${wordPressSiteUrl}/wp-json/repas/idrepas/idprog=${IdProg}/repasday=${date.toLocaleDateString('fr')}`)
          .then((prog) => {
            if (prog.data.length > 0) {SetRepasExist(true)}
          })
          .catch((error) => {
            /* console.error(`onRejected Id Meal function called: ${error.message}`); */
          });
    }
    const getidprogrambylead = () => {
      axios
        .get(`${wordPressSiteUrl}/wp-json/repas/idprogrambylead/${Id}`)
          .then((prog) => {
            setIdProg(prog.data[0].Id_Program);
          })
          .catch((error) => {
            /* console.error(`onRejected Id Prog function called: ${error.message}`); */
          });
    }
    
    useEffect(() => {
      getidprogrambylead();
      if(IdProg){
        RepasExist();
      }
      console.log(repasexist, Nr)
    }, [IdProg]);

    const onFinish = () => {
      rp.splice(0, 5, rpArray);
      const formData = {
        Id_Program: parseInt(IdProg),
        Repas_Day: formatDate(date),
        Repas: JSON.stringify(rp[0])
      }
      if (formData.Repas.length > 2) {
        if(repasexist === true){
          axios.post(`${wordPressSiteUrl }/wp-json/repas/u`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ authToken }`
              }
            })
            .then( console.log(formData))
            .catch((error) => {
              console.error(`onRejected Update meal function called: ${error.message}`);
            })
          }
        else{
          axios.post(`${wordPressSiteUrl }/wp-json/repas/i`, formData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${ authToken }`
            }
          })
          .then((res) => {
            SetRepasExist(true)
          })
          .catch((error) => {
            console.error(`onRejected Create meal function called: ${error.message}`);
          })
        }
      }
    };
  
  if(Nr === "2-fois"){
    return (
      <Row gutter={[24, 24]}>
        <Title level={2}>Jour {date.toLocaleDateString('fr')}</Title>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16}} >
          <Title level={4}>Petit-déjeuner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp1}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Please select"
            allowClear
            treeDefaultExpandAll
            onChange={onChangerp1}
          >
            {Foods.map (food => (
              <TreeNode value={food.foodName} title={food.foodName} key={food.id} />
            ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16}} >
          <Title level={4}>Dîner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp2}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Please select"
            allowClear
            treeDefaultExpandAll
            onChange={onChangerp2}
          >
            {Foods.map (food => (
              <TreeNode value={food.id} title={food.foodName} key={food.id} />
            ))}
          </TreeSelect>
        </Col>
      </Row>
    )
  }
  else if(Nr === "3-fois"){
    return (
      <Form name="repas1" onFinish={onFinish} autoComplete="off">
        <Title level={2}>Jour {date.toLocaleDateString('fr')}</Title>
      <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8}} >
        <Title level={4}>Petit-déjeuner</Title>
          <TreeSelect 
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp1}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            allowClear
            treeDefaultExpandAll
            onChange={onChangerp1}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8}} >
        <Title level={4}>Déjeuner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp2}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp2}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8}} >
        <Title level={4}>Dîner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp3}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp3}>
            {Foods.map (food => (
              <TreeNode value={food.id} title={food.foodName} key={food.id} />
            ))}
          </TreeSelect>
        </Col>
      </Row><br />
      <Row >
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 5 }}> 
          <Title level={5}>Protéines : {protein} Gr</Title>
        </Col>
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 5 }}> 
          <Title level={5}>Lipides : {lipide} Gr</Title>
        </Col>
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 5 }}> 
          <Title level={5}>Glucides : {glucide} Gr</Title>
        </Col>
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 7 }}> 
          <Title level={5}>Calories : {calories} Kcal</Title>
        </Col>
        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 2 }}>  
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Form.Item>
        </Col>
      </Row>
      </Form>
    )
  }
  else if(Nr === "4-fois"){
    return (
      <Form name="repas1" onFinish={onFinish} autoComplete="off">
      <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6}} >
        <Title level={4}>Petit-déjeuner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp1}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp1}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6}} >
        <Title level={4}>Collation</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp2}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp2}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6}} >
        <Title level={4}>Déjeuner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp3}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp3}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6}} >
        <Title level={4}>Dîner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp4}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp4}>
            {Foods.map (food => (
              <TreeNode value={food.id} title={food.foodName} key={food.id} />
            ))}
          </TreeSelect>
        </Col>
      </Row><br />
      <Row justify="end">
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 22 }}> 
          <Title level={5}>Calories : {calories} Kcal</Title>
        </Col>
        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 2 }}>  
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Form.Item>
        </Col>
      </Row>
      </Form>
    )
  }
  else if(Nr === "5-fois"){
    return (
      <Form name="repas1" onFinish={onFinish} autoComplete="off">
      <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8}} >
        <Title level={4}>Petit-déjeuner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp1}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp1}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8}} >
        <Title level={4}>Déjeuner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp2}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp2}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8}} >
        <Title level={4}>Dîner</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp3}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp3}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} >
        <Title level={4}>Collation 1</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp4}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp4}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} >
        <Title level={4}>Collation 2</Title>
          <TreeSelect
            showSearch
            treeNodeFilterProp='title'
            style={{ width: '100%' }}
            value={rp5}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Veuillez sélectionner"
            treeDefaultExpandAll
            onChange={onChangerp5}>
              {Foods.map (food => (
                <TreeNode value={food.id} title={food.foodName} key={food.id} />
              ))}
          </TreeSelect>
        </Col>
      </Row><br />
      <Row justify="end">
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 22 }}> 
          <Title level={5}>Calories : {calories} Kcal</Title>
        </Col>
        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 2 }}>  
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Form.Item>
        </Col>
      </Row>
      </Form>
    )
  }
}
export default MakeProgramItem;