import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import clientConfig from '../../../client-config';
import { Typography, Image, Spin, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
const { Title } = Typography;

export class PostItem extends Component {
    constructor( props ) {
        super( props);
        this.state = {
            imgUrl: '',
            posts : [],
            isLoaded: false
        }
    }
    static propTypes = {
        post: PropTypes.object.isRequired
    }
    componentDidMount() {
        const { featured_media } = this.props.post;
        const wordPressSiteUrl = clientConfig.siteUrl;
        const getImageUrl = axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/media/${featured_media}`);
        Promise.all([getImageUrl]).then(res =>{
            this.setState({
                imgUrl: res[0].data.media_details.sizes.full.source_url,
                isLoaded: true
            });
        });
    }
  render() {
      const {id, title } = this.props.post;
      const { imgUrl, isLoaded } = this.state;
      
      if(isLoaded) {
        return (
            <>
                <Link to={{
                            pathname: `/post/${id}`,
                            state: {
                                img: imgUrl,
                            }
                    }}>
                        <Image preview={false} alt={title.rendered} height={200} width={"100%"} src={imgUrl} />
                </Link>
                <Link to={`/post/${id}`}><center><Title level={5}>{title.rendered}</Title></center></Link>
            </>
          )
      }
      return  <div className='main'>
                <div className="App">
                    <Row id="feature" className="block featureBlock">
                        <Col xs={{ span: 24 }} >
                            <div className="container-fluid">
                                <div className="CenterInside"><Spin /></div>
                            </div>
                        </Col>
                    </Row>
                </div>
              </div>;
    
  }
}

export default PostItem;