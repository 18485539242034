import React from 'react';
import AppQuiz from '../components/home/quiz';

function Quiz (){
    return (
        <div className='main'>
            <AppQuiz />
        </div>
    );
}

export default Quiz;