import React from 'react';
import '@fortawesome/react-fontawesome';
import { BackTop } from 'antd';
import { UpSquareFilled, FacebookFilled, TwitterSquareFilled, LinkedinFilled, InstagramFilled} from '@ant-design/icons';

function AppFooter() {
  return (
    <div className="container-fluid">
      <div className="footer">
        <div className="logo">
            <a title="MealApp" href="/"></a>
        </div>
        <ul className="socials">
          <li><a href="https://www.facebook.com/"><FacebookFilled /></a></li>
          <li><a href="https://www.twitter.com/"><TwitterSquareFilled /></a></li>
          <li><a href="https://www.linkedin.com/"><LinkedinFilled /></a></li>
          <li><a href="https://www.pinterest.com/"><InstagramFilled /></a></li>
        </ul>
        <div className="copyright">Copyright &copy; 2022 Astraldigital</div>
        <BackTop>
          <div className="goTop"><UpSquareFilled style={{color:"#1890ff", fontSize:"42px"}}/></div>
        </BackTop>
      </div>
    </div>
  );
}

export default AppFooter;