import React, {useState} from "react";
import { Form, Row, Col, Typography, Button, Space, Image, Checkbox, Switch } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import Turquie from './img/Turquie.svg'; import Bœuf from './img/Bœuf.svg';
import Poulet from './img/Poulet.svg'; import Poisson from './img/Poisson.svg';
import Fruitmer from './img/Fruitmer.svg';
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    viande: defaults
};
const AppStep25 = ({ next, prev, handleFormData, values }) => {
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const submitFormData = (e) => {
        if (switched === true) {
            values.viande = JSON.stringify(defaults);
        }
        else if (switched === false){
            values.viande = JSON.stringify(e.viande);
        }
        onChange(e);
        next();
    }
    const onSwitch = (e) => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    return(
        <Form 
            name="step25"
            onFinish={submitFormData}
            initialValues={initValues}
        >
            <Title level={2}>Cochez la viande et le poisson que vous souhaitez inclure :</Title>
            <Form.Item
                    name="viande"
                    checkedvalues={values.viande}
                    onChange={handleFormData("viande")}
                >
            <CheckboxGroup
                className="boxe"
                style={{ width: '100%' }}
                onChange={onChange}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Checkbox value="Turquie" disabled={disabled}><Text><Image width={18} src={Turquie} preview={false}/> Turquie</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Bœuf" disabled={disabled}><Text><Image width={18} src={Bœuf} preview={false}/> Bœuf</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Poulet" disabled={disabled}><Text><Image width={18} src={Poulet} preview={false}/> Poulet</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Poisson" disabled={disabled}><Text><Image width={18} src={Poisson} preview={false}/> Poisson</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Fruit de mer" disabled={disabled}><Text><Image width={18} src={Fruitmer} preview={false}/> Fruit de mer</Text></Checkbox>
                        </Col>
                    </Row>
            </CheckboxGroup>
            </Form.Item>
            <Row justify="end">
                <Col xs={{ span: 10 }} sm={{ span: 11 }} md={{ span: 11 }} lg={{ span: 9 }} xl={{ span: 7 }}>
                    <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
                </Col>
                <Col xs={{ span: 2 }} sm={{ span: 1 }} md={{ span: 1 }}>
                    <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
                        <Switch checked={switched} onChange={onSwitch} />
                    </Form.Item>
                </Col>
              </Row>
            <br />
            
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep25;