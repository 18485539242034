import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const AppStep10 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
        await delay(500);
        next();
      };
    return(
        <Form 
            name="step10"
            onChange={submitFormData}
        >
            <Title level={2}>Combien de fois avez-vous essayé de perdre du poids ?</Title>
            <Form.Item 
                name="tried_lose_weight"
                defaultValue={values.tried_lose_weight}
                onChange={handleFormData("tried_lose_weight")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
            <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="Jamais" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Jamais</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="1-2 fois" />
                        <label htmlFor="control_02">
                                <Text strong>1-2 fois</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="beaucoup de tentatives" />
                        <label htmlFor="control_03">
                                <Text strong>J'ai eu beaucoup de tentatives</Text>
                        </label>
                    </div>
                </Radio.Group>
            </section>
            </Form.Item>

            <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep10;