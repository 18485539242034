import React from 'react';
import { Form, Row, Col, Typography, Radio, Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title } = Typography;

const AppStep2 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
          await delay(500);
        next();
    };
      
    return(
        <Form 
            name="step2"
            onChange={submitFormData}
        >
            <Title level={2}>Sélectionnez votre sexe</Title>
            <Form.Item 
                name="sexe"
                defaultValue={values.sexe}
               /*  onChange={() => {handleFormData("sexe"); submitFormData()}} */
               onChange={handleFormData("sexe")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <Radio.Group buttonStyle="solid">
                    <Row gutter={[24, 24]}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
                            <Radio.Button id="man" value="Homme" htmlFor="man" size="large" className="radiobutn">Homme</Radio.Button>
                            <label className='lose-cc man bgblue' htmlFor="man"></label>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
                            <Radio.Button id="woman" value="Femme" htmlFor="woman" size="large" className="radiobutn">Femme</Radio.Button>
                            <label className='lose-cc woman bgred' htmlFor="woman"></label>
                        </Col>
                    </Row>
                </Radio.Group>
            </Form.Item>
            <Row justify="start">
                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                    <Form.Item  className="steps-action">
                    <Space style={{ width: '100%' }}>
                        <Button type="primary" shape="circle" onClick={prev} icon={<ArrowLeftOutlined />} />
                    </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default AppStep2;