import React from "react";
import { Typography, Image, Row, Col, Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import behav from './img/behav.svg';

const { Title } = Typography;
const AppStep7 = ({ next, prev }) => {
    
    return(
        <div>
            <Row justify="end">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}  className="center" >
                    <Title level={2}>Changement de comportement<br /> vs régime restrictif</Title>
                    <Title level={5}>Nous travaillons par la création d'habitudes, ce qui donne des résultats durables</Title>
                    <Image width={'80%'} src={behav} preview={false} />
                </Col>
            </Row>
            <Row justify="end">
                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                    <Space style={{ width: '100%' }}>
                        <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                        <Button type="primary" onClick={next}>
                            Continue
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}
export default AppStep7;