import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const AppStep14 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
          await delay(500);
        next();
      };
    return(
        <Form 
            name="step14"
            onChange={submitFormData}
        >
            <Title level={2}>Est-ce que tu fais du sport ?</Title>
            <Title level={5}>Il est important de prendre en considération le niveau d'activité pour un Homme qui veut prendre 10 kg et travaille au bureau</Title>
            <Form.Item 
                name="entrainement_par_semaine"
                defaultValue={values.entrainement_par_semaine}
                onChange={handleFormData("entrainement_par_semaine")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="rien" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Presque rien</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="promenades" />
                        <label htmlFor="control_02">
                                <Text strong>Seulement des promenades</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="1-2-fois" />
                        <label htmlFor="control_03">
                                <Text strong>1 à 2 fois par semaine</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_04" name="select" value="3-4-fois" />
                        <label htmlFor="control_04">
                                <Text strong>3 à 4 fois par semaine</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_05" name="select" value="5-7-fois" />
                        <label htmlFor="control_05">
                                <Text strong>5 à 7 fois par semaine</Text>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                    {/* <Button type="primary" htmlType="submit">
                        Continue
                    </Button> */}
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep14;