import React, { useState } from 'react';
import EditLeadBasic from '../leadedit/editleadbasic';
import { Descriptions, Button, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';

const LeadBasic = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
        <Descriptions title="Informations basiques" column={{ md: 2, sm: 1, xs: 1 }} extra={<Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />}>
          <Descriptions.Item label="Adresse E-mail" span={2}>{dataLead[0].email}</Descriptions.Item>
          <Descriptions.Item label="Perdre poids">{dataLead[0].perdrepoids}</Descriptions.Item>
          <Descriptions.Item label="Sexe">{dataLead[0].sexe}</Descriptions.Item>
        </Descriptions>

        <Modal
            title="Informations basiques"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadBasic dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
    );
}
export default LeadBasic;