import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Checkbox, Form, Button, Typography, Row, Col, Divider, Switch, Image } from 'antd';
import Citrus from '../../../home/steps/img/Citrus.png'; import Pomme from '../../../home/steps/img/Pomme.png';
import Poire from '../../../home/steps/img/Poire.png'; import Kiwi from '../../../home/steps/img/Kiwi.svg';
import Bananes from '../../../home/steps/img/Bananes.svg'; import Fruitstropicaux from '../../../home/steps/img/Fruitstropicaux.svg';
import Kaki from '../../../home/steps/img/Kaki.svg'; import Peche from '../../../home/steps/img/Peche.svg';
import Baies from '../../../home/steps/img/Baies.svg'; import Raisin from '../../../home/steps/img/Raisin.svg';
import Grenat from '../../../home/steps/img/Grenat.svg';
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    fruits: defaults
};
const EditLeadFruits = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    const ufruits ={
        Id: dataLead[0].Id,
        fruits: dataLead[0].fruits,
      };
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const onSwitch = () => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    const submitForm = (e) => {
        if (switched === true) {
            ufruits.fruits = JSON.stringify(defaults);
        }
        else if (switched === false){
            ufruits.fruits = JSON.stringify(e.fruits);
        }
        onChange(e);
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/ufruits`, ufruits)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }
    return(
      <Form onFinish={submitForm} initialValues={initValues} >
        <Form.Item
          name="fruits"
          value={ufruits.fruits}
          onChange={(e) => {ufruits.fruits = e.target.value }}
        >
          <CheckboxGroup
            className="boxe"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Checkbox value="Citrus" disabled={disabled}><Text><Image width={18} src={Citrus} preview={false}/> Citrus</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Pomme" disabled={disabled}><Text><Image width={18} src={Pomme} preview={false}/> Pomme</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Poire" disabled={disabled}><Text><Image width={18} src={Poire} preview={false}/> Poire</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Kiwi" disabled={disabled}><Text><Image width={18} src={Kiwi} preview={false}/> Kiwi</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Bananes" disabled={disabled}><Text><Image width={18} src={Bananes} preview={false}/> Bananes</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Fruits tropicaux" disabled={disabled}><Text><Image width={18} src={Fruitstropicaux} preview={false}/> Fruits tropicaux</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Kaki" disabled={disabled}><Text><Image width={18} src={Kaki} preview={false}/> Kaki</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="La pêche" disabled={disabled}><Text><Image width={18} src={Peche} preview={false}/> La pêche</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Baies" disabled={disabled}><Text><Image width={18} src={Baies} preview={false}/> Baies</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Raisin" disabled={disabled}><Text><Image width={18} src={Raisin} preview={false}/> Raisin</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Grenat" disabled={disabled}><Text><Image width={18} src={Grenat} preview={false}/> Grenat</Text></Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        
        <Row justify="end">
          <Col xs={{ span: 9 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>
            <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
          </Col>
          <Col span={2}>
            <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
              <Switch checked={switched} onChange={onSwitch} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadFruits;