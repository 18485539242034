import React, { useState } from 'react';
import EditLeadAllergies from '../leadedit/editleadallergies';
import { List, Typography, Button, Col, Row, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';
const { Title } = Typography;

const LeadAllergies = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    let allergies = JSON.parse(dataLead[0].allergies.split());

    return (
        <>
        <List
          size="small"
          header={
          <Row gutter={[24, 24]}>
            <Col span={21}>
              <Title level={5}>Restrictions alimentaires ou Allergies :</Title>
            </Col>
            <Col span={3}>
              <Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />
            </Col>
          </Row>}
          bordered
          dataSource={allergies}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <Modal
            title="Avez-vous des restrictions alimentaires ou des allergies ?"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadAllergies dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
        );
    }
    export default LeadAllergies;