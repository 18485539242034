import React, { useEffect, useState } from 'react';
import axios from "axios";
import clientConfig from '../../../client-config';
import { Link } from 'react-router-dom';
import { Col, Row, Spin, Typography, Image, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import LeadBasic from './leadinfo/leadbasic';
import LeadMeasurments from './leadinfo/leadmeasurements';
import LeadDaily from './leadinfo/leaddaily';
import LeadVraisofaux from './leadinfo/leadvraisofaux';
import LeadAppareils from './leadinfo/leadappareils';
import LeadAllergies from './leadinfo/leadallergies';
import LeadLegumes from './leadinfo/leadlegumes';
import LeadCereales from './leadinfo/leadcereales';
import LeadProduits from './leadinfo/leadproduits';
import LeadViande from './leadinfo/leadviande';
import Leadfruits from './leadinfo/leadfruits';
import AppImc from '../../home/imc';
import AppDcn from '../../home/steps/dcn';
import AppChart from '../../home/chart';
import After_first_week from '../../home/steps/img/After_first_week.svg'; import Calories from '../../home/steps/img/Calories.svg';
import Water from '../../home/steps/img/Water.svg'; import Duration from '../../home/steps/img/Duration.svg';
import Age from '../../home/steps/img/Age.svg'; import Height from '../../home/steps/img/Height.svg';
const { Title, Text } = Typography;

const Leaduser = () => {
    const location = useLocation()
    const { Id } = location.state
    const [dataLead, setDataLead] = useState([]);
    const [loading, setLoading] = useState(false);
    const [views, setViews] = useState(0);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const wordPressSiteUrl = clientConfig.siteUrl;
    
    useEffect(() => {
        if (isInitialRender) {
            getleads();
            setViews(views + 1);
            setIsInitialRender(false);
          }
    }, [views, isInitialRender]);

    const getleads = () => {
        axios
            .get(`${wordPressSiteUrl}/wp-json/leads/id/${Id}`)
            .then((res) => {
                setDataLead(res.data);
                setLoading(true);
            });
    }
   
    if (loading) {
      let gain = parseInt(dataLead[0].poidssouhaite) - parseInt(dataLead[0].poidsactuel);
    return (
      <div className='main'>
        <div className="App">
          <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
            <Row gutter={[16, 8]}>
            <Col span={24} className="resultsblock">
              <Col span={24} className="results">
                <LeadBasic dataLead={dataLead} setIsInitialRender={setIsInitialRender}/>
              </Col>
              <Col span={24} className="results">
                <LeadMeasurments dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
              </Col>
            </Col>
            <Col xs={ {span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="resultsblock">
              <Col span={24} className="results">
                {dataLead[0].perdrepoids === "Oui" &&
                 <Title level={4}>Prévision de perdre de poids {parseInt(gain)} Kg</Title>
                }
                {dataLead[0].perdrepoids === "Non" &&
                 <Title level={4}>Prévision de prise de poids {parseInt(gain)} Kg</Title>
                }
                <AppChart values={dataLead[0]} />
              </Col>
            </Col>
            <Col xs={ {span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="resultsblock">
              <Col span={24} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 5 }} sm={{ span: 5 }} md={{ span: 4}}>
                    <Image width={60} src={After_first_week} preview={false}/>
                  </Col>
                  <Col s={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20}}>
                    <Text>Après la première semaine</Text>
                    <Title level={3}>{parseInt(gain)/4} Kg</Title>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 5 }} sm={{ span: 5 }} md={{ span: 4}}>
                    <Image width={60} src={Water} preview={false}/>
                  </Col>
                  <Col xs={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20}}>
                    <Text>Minimum d'eau quotidien</Text>
                    <Title level={3}>2.1 L</Title>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 5 }} sm={{ span: 5 }} md={{ span: 4}}>
                    <Image width={60} src={Calories} preview={false}/>
                  </Col>
                  <Col xs={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20}}>
                    <Text>Norme calorique quotidienne</Text>
                    <AppDcn values={dataLead[0]} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 5 }} sm={{ span: 5 }} md={{ span: 4}}>
                    <Image width={60} src={Duration} preview={false}/>
                  </Col>
                  <Col xs={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20}}>
                    <Text>Premier résultat visible en</Text>
                    <Title level={3}>10 jours</Title>
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xs={ {span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="resultsblock">
              <Col span={24} className="results">
                <AppImc values={dataLead[0]} />
              </Col>
            </Col>
            <Col xs={ {span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="resultsblock">
              <Col span={24} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 5 }} sm={{ span: 5 }} md={{ span: 4}}>
                    <Image width={60} src={Age} preview={false}/>
                  </Col>
                  <Col xs={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20}}>
                    <Text>Age</Text>
                    <Title level={3}>{dataLead[0].age} ans</Title>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 5 }} sm={{ span: 5 }} md={{ span: 4}}>
                    <Image width={60} src={Height} preview={false}/>
                  </Col>
                  <Col xs={{ span: 19 }} sm={{ span: 19 }} md={{ span: 20}}>
                    <Text>Hauteur / Poids</Text>
                    <Title level={3}>{dataLead[0].taille} cm / {dataLead[0].poidsactuel} kg</Title>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="resultsblock">
            <Col span={24} className="results">
              <LeadDaily dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadVraisofaux dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadAppareils dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadAllergies dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadLegumes dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadCereales dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadProduits dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <LeadViande dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}}>
              <Leadfruits dataLead={dataLead} setIsInitialRender={setIsInitialRender} />
            </Col>
          </Row>
          
             
            </Col>
          </Row>
          <Row id="feature" className="block fixedbottom ">
          <Col xs={{ span: 16, offset: 4 }} sm={{ span: 12, offset: 6 }} md={{ span: 6, offset: 9 }} >
            <Link to={{pathname:`/program/${Id}`, state:{Id: Id}}}><Button type="primary" shape="round" size="large" block><Text strong style={{color: '#fff'}}>Obtenir mon plan</Text></Button></Link>
          </Col>
        </Row>
        </div>
      </div>
    )
  }
    return <div className='main'>
        <div className="App">
            <Row id="feature" className="block featureBlock">
                <Col xs={{ span: 24 }} >
                    <div className="container-fluid">
                        <div className="CenterInside"><Spin /></div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>;
}

export default Leaduser;