import React, { useState } from 'react';
import EditLeadViande from '../leadedit/editleadviande';
import { List, Typography, Button, Col, Row, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';
const { Title } = Typography;

const LeadViande = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    let viande = JSON.parse(dataLead[0].viande.split());
    return (
        <>
        <List
          size="small"
          header={
          <Row gutter={[24, 24]}>
            <Col span={21}>
              <Title level={5}>La viande et le poisson que vous souhaitez inclure :</Title>
            </Col>
            <Col span={3}>
              <Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />
            </Col>
          </Row>}
          bordered
          dataSource={viande}
          renderItem={(item) => <List.Item>{item}</List.Item>}
          />

        <Modal
            title="Cochez la viande et le poisson que vous souhaitez inclure :"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadViande dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
        );
    }
    export default LeadViande;