import React, { useEffect, useState } from 'react';
import axios from "axios";
import clientConfig from '../../../client-config';
import { Col, Row, Spin, Descriptions, PageHeader, Divider, List, Typography, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
const { Title } = Typography;

const Lead = () => {
    const location = useLocation()
    const { Id } = location.state
    const [dataLead, setDataLead] = useState([]);
    const [loading, setLoading] = useState(false);
    const wordPressSiteUrl = clientConfig.siteUrl;

    useEffect(() => {
      axios
        .get(`${wordPressSiteUrl}/wp-json/leads/id/${Id}`)
        .then((res) => {
            setDataLead(res.data);
            setLoading(true);
        });
    }, []);

    if (loading) {
        
    var nrepas;
    if (dataLead[0].nrepas === "2-fois"){ nrepas = "2 Fois par jour" }
    else if (dataLead[0].nrepas === "3-fois"){ nrepas = "3 Fois par jour" }
    else if (dataLead[0].nrepas === "4-fois"){ nrepas = "4 Fois par jour" }
    else if (dataLead[0].nrepas === "5-fois"){ nrepas = "5 Fois par jour" }

    var entrainement_par_semaine
    if (dataLead[0].entrainement_par_semaine === "rien"){ entrainement_par_semaine = "Presque rien" }
    else if (dataLead[0].entrainement_par_semaine === "promenades"){ entrainement_par_semaine = "Seulement des promenades" }
    else if (dataLead[0].entrainement_par_semaine === "1-2-fois"){ entrainement_par_semaine = "1 à 2 fois par semaine" }
    else if (dataLead[0].entrainement_par_semaine === "3-4-fois"){ entrainement_par_semaine = "3 à 4 fois par semaine" }
    else if (dataLead[0].entrainement_par_semaine === "5-7-fois"){ entrainement_par_semaine = "5 à 7 fois par semaine" }

    var ideal_weight_reached;
    if (dataLead[0].ideal_weight_reached === "-1"){ ideal_weight_reached = "Moins de 1 an" }
    else if (dataLead[0].ideal_weight_reached === "1-3"){ ideal_weight_reached = "1-3 ans" }
    else if (dataLead[0].ideal_weight_reached === "+3"){ ideal_weight_reached = "Plus de 3 ans" }
    else if (dataLead[0].ideal_weight_reached === "jamais"){ ideal_weight_reached = "Je n'ai jamais atteint mon poids idéal" }
    
    var tried_lose_weight;
    if (dataLead[0].tried_lose_weight === "Jamais"){ tried_lose_weight = "Jamais" }
    else if (dataLead[0].tried_lose_weight === "1-2 fois"){ tried_lose_weight = "1-2 fois" }
    else if (dataLead[0].tried_lose_weight === "beaucoup de tentatives"){ tried_lose_weight = "J'ai eu beaucoup de tentatives" }
    
    var dormir;
    if (dataLead[0].dormir === "<5"){ dormir = "Moins de 5 heures" }
    else if (dataLead[0].dormir === "5-6"){ dormir = "5-6 heures" }
    else if (dataLead[0].dormir === "7-8"){ dormir = "7-8 heures" }
    else if (dataLead[0].dormir === ">8"){ dormir = "Plus de 8 heures" }

    var eau;
    if (dataLead[0].eau === "Café ou thé seulement"){ eau = "cafe-the" }
    else if (dataLead[0].eau === "<2-verres"){ eau = "Moins de 2 verres" }
    else if (dataLead[0].eau === "2-6-verres"){ eau = "2-6 verres" }
    else if (dataLead[0].eau === "7-10-verres"){ eau = "7-10 verres" }
    else if (dataLead[0].eau === ">10-verres"){ eau = "Plus de 10 verres" }

    var temppreprepas;
    if (dataLead[0].temppreprepas === "<30min"){ temppreprepas = "Moins de 30 minutes" }
    else if (dataLead[0].temppreprepas === "30-60min"){ temppreprepas = "30-60 minutes" }
    else if (dataLead[0].temppreprepas === ">1h"){ temppreprepas = "Plus d'une heure" }

    let vraisofaux = JSON.parse(dataLead[0].vraisofaux.split());
    let appareils = JSON.parse(dataLead[0].appareils.split());
    let allergies = JSON.parse(dataLead[0].allergies.split());
    let legumes = JSON.parse(dataLead[0].legumes.split());
    let produits = JSON.parse(dataLead[0].produits.split());
    let cereales = JSON.parse(dataLead[0].cereales.split());
    let viande = JSON.parse(dataLead[0].viande.split());
    let fruits = JSON.parse(dataLead[0].fruits.split());

  return (
    <div className='main'>
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 24 }} >
            <div className="container-fluid">
              <div className="site-page-header-ghost-wrapper">
                <PageHeader
                  ghost={false}
                  onBack={() => window.history.back()}
                  title="Tableau de bord"
                  extra={[<Link
                    to={{pathname:`/program/${Id}`,
                    state: {
                      Id: Id,
                      Nr: dataLead[0].nrepas,
                    }
                  }}>
                    <Button key={Id} type="primary">Vérifier le programme diététique</Button>
                    </Link>]}
                  ><Divider />
                    <Descriptions column={2}>
                      <Descriptions.Item key={1} label="Adresse E-mail">{dataLead[0].email}</Descriptions.Item>
                      <Descriptions.Item key={2} label="Perdre poids">{dataLead[0].perdrepoids}</Descriptions.Item>
                      <Descriptions.Item key={3} label="Updates">{dataLead[0].updates}</Descriptions.Item>
                      <Descriptions.Item key={4} label="Sexe">{dataLead[0].sexe}</Descriptions.Item>
                    </Descriptions><Divider />
                    <Descriptions column={3}>
                      <Descriptions.Item key={11} label="Poids souhaitée">{dataLead[0].poidssouhaite}</Descriptions.Item>
                      <Descriptions.Item key={12} label="Age">{dataLead[0].age}</Descriptions.Item>
                      <Descriptions.Item key={13} label="Taille">{dataLead[0].taille}</Descriptions.Item>
                      <Descriptions.Item key={14} label="Poids actuel">{dataLead[0].poidsactuel}</Descriptions.Item>
                      <Descriptions.Item key={15} label="Type de corps">{dataLead[0].bodytype}</Descriptions.Item>
                      <Descriptions.Item key={16} label="Type de journée">{dataLead[0].journeetype}</Descriptions.Item>
                      </Descriptions><Divider />
                    <Descriptions column={1}>
                      <Descriptions.Item key={21} label="Ressentez-vous des maux d'estomac pendant la journée ?">{dataLead[0].mauxestomac}</Descriptions.Item>
                      <Descriptions.Item key={22} label="Depuis combien de temps n'avez-vous pas atteint votre poids idéal ?">{ideal_weight_reached}</Descriptions.Item>
                      <Descriptions.Item key={23} label="Combien de fois avez-vous essayé de perdre du poids ?">{tried_lose_weight}</Descriptions.Item>
                      <Descriptions.Item key={24} label="Nombre de repas par jour">{nrepas}</Descriptions.Item>
                      <Descriptions.Item key={25} label="Nombre d'entrainements par semaine">{entrainement_par_semaine}</Descriptions.Item>
                      <Descriptions.Item key={26} label="Comment est votre énergie au jour le jour ?">{dataLead[0].energie}</Descriptions.Item>
                      <Descriptions.Item key={27} label="Combien dormez-vous ?">{dormir}</Descriptions.Item>
                      <Descriptions.Item key={28} label="Combien d'eau buvez-vous quotidiennement ?">{eau}</Descriptions.Item>
                      <Descriptions.Item key={29} label="Combien de temps êtes-vous prêt à consacrer à la préparation d'un repas ?">{temppreprepas}</Descriptions.Item>
                      <Descriptions.Item key={30} label="Vous aimeriez inclure des aliments surgelés ou prêts à cuire dans votre plan de repas?">{dataLead[0].alimentssurgeles}</Descriptions.Item>
                    </Descriptions><Divider />
                </PageHeader>
              </div>
              <Row gutter={[24, 24]}>
                <Col span={8}>
                  <List
                    key="vraisofaux"
                    size="small"
                    header={<div><Title level={5}>Sélectionnez tout ce qui est vrai pour vous :</Title></div>}
                    bordered
                    dataSource={vraisofaux}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col>
                <Col span={8}>
                  <List
                    key="appareils"
                    size="small"
                    header={<div><Title level={5}>Quels appareils de cuisine avez-vous ?</Title></div>}
                    bordered
                    dataSource={appareils}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col>
                <Col span={8}>
                  <List
                    key="allergies"
                    size="small"
                    header={<div><Title level={5}>Restrictions alimentaires ou Allergies ?</Title></div>}
                    bordered
                    dataSource={allergies}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col><Divider />
                <Col span={8}>
                  <List
                    key="legumes"
                    size="small"
                    header={<div><Title level={5}>Les légumes que vous souhaitez inclure :</Title></div>}
                    bordered
                    dataSource={legumes}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col>
                <Col span={8}>
                  <List
                    key="cereales"
                    size="small"
                    header={<div><Title level={5}>Les céréales que vous souhaitez inclure :</Title></div>}
                    bordered
                    dataSource={cereales}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col>
                <Col span={8}>
                  <List
                    key="produits"
                    size="small"
                    header={<div><Title level={5}>Les produits que vous souhaitez inclure :</Title></div>}
                    bordered
                    dataSource={produits}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col><Divider />
                <Col span={12}>
                  <List
                    key="viande"
                    size="small"
                    header={<div><Title level={5}>La viande et le poisson que vous souhaitez inclure :</Title></div>}
                    bordered
                    dataSource={viande}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                    />
                </Col>
                <Col span={12}>
                  <List
                    key="fruits"
                    size="small"
                    header={<div><Title level={5}>Les fruits que vous souhaitez inclure :</Title></div>}
                    bordered
                    dataSource={fruits}
                    renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
  return 
    <div className='main'>
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 24 }} >
            <div className="container-fluid">
              <div className="CenterInside"><Spin /></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>;
}

export default Lead;