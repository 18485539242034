import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const AppStep9 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
          await delay(500);
        next();
    };

    return(
        <Form 
            name="step9"
            onChange={submitFormData}
        >
            <Title level={2}>Depuis combien de temps n'avez-vous pas atteint votre poids idéal ?</Title>
            <Form.Item 
                name="ideal_weight_reached"
                defaultValue={values.ideal_weight_reached}
                onChange={handleFormData("ideal_weight_reached")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="-1" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Moins de 1 an</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="1-3" />
                        <label htmlFor="control_02">
                                <Text strong>1-3 ans</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="+3" />
                        <label htmlFor="control_03">
                                <Text strong>Plus de 3 ans</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_04" name="select" value="jamais" />
                        <label htmlFor="control_04">
                                <Text strong>Je n'ai jamais atteint mon poids idéal</Text>
                        </label>
                    </div>
                </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep9;