import React from 'react';
import { Line } from '@ant-design/plots';

const AppChart = ( {values}) => {
  const { poidssouhaite, poidsactuel } = values;
  var pi = parseInt(poidsactuel)
  var pf = parseInt(poidssouhaite)
  var p2 = pi + ((pf - pi) /3)
  var p3 = p2 + ((pf - p2) /3)
  var p4 = p3 + ((pf - p3) /3)
  var pfmax = 0;
  var pimin = 0;
 if (pi - pf < 0){
  pfmax = pf +1;
  pimin = pi -1;
 } else if (pi - pf > 0){
  pfmax = pf -1;
  pimin = pi +1;
 }

    const data = [
        { semaine: '1', poids: pi },
        { semaine: '2', poids: parseFloat(p2).toFixed(1) },
        { semaine: '3', poids: parseFloat(p3).toFixed(1) },
        { semaine: '4', poids: parseFloat(p4).toFixed(1) },
        { semaine: '5', poids: pf },
      ];
    
      const config = {
        data,
        xField: 'semaine',
        yField: 'poids',
        padding: 'auto',
        autoFit: false,
        height: 360,
        // smooth: true,
        color: '#5AD8A6',
        point: {
          visible: false,
          size: 12,
          shape: 'circle',
          style: {
            // fill: '#5AD8A6',
            stroke: '#5AD8A6',
            lineWidth: 8,
          },
        },
        line: {
            size: 11,   
        },
        yAxis: {
            visible: true,
            tickCount: 4,
            max: pfmax,
            min: pimin,
        },
        xAxis: {
            visible: false,
            tickCount: 5,
            min: 0,
            max: 4
        },
      };
      return <Line {...config} />;
};

export default AppChart;