import React, { useEffect, useState }  from 'react';
import clientConfig from '../../../../client-config';
import axios from 'axios';
import Foods from '../../../../assets/food2.json';
import { Divider, Row, Col, Spin, Card, Button, Typography  } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'; 
import { Link, useLocation } from 'react-router-dom';
const { Title } = Typography;
const { Meta } = Card;

const DisplayProgram = () => {
    const location = useLocation()
    const { Id, Nr } = location.state
    console.log(Id, Nr)
    const [loading, setLoading] = useState(false);
    const [IdProgram, setIdProgram] = useState(0);
    const [RepasDay, setRepasDay] = useState([]);
    const [Repas, setRepas] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const wordPressSiteUrl = clientConfig.siteUrl;
    const [ProgramExist, setProgramExist] = useState(false)

    useEffect(() => {
            axios
            .get(`${wordPressSiteUrl}/wp-json/repas/idprogrambylead/${Id}`)
            .then((res) => {
              if(res.data.length === 0 ){
                setProgramExist(false)
                setLoading(true)
              }
              else{
                setIdProgram(res.data[0].Id_Program);
                setProgramExist(true)
              }
              setLoading(true)
            })
            .catch((error) => {
            console.error(`onRejected program function called: ${error.message}`);
            });
          setIsInitialRender(false);
        
        const getRepas = async () => {
          await axios
            .get(`${wordPressSiteUrl}/wp-json/repas/idprogram/${IdProgram}`)
            .then((rep) => {
                rep.data.map(RepasD => setRepasDay(RepasDay => [...RepasDay, RepasD.Repas_Day]))
                rep.data.map(RepasN => setRepas(RepasNb => [...RepasNb, JSON.parse(RepasN.Repas)]))
            })
            .catch((error) => {
                console.error(`onRejected repas function called: ${error.message}`);
            });
        }
        getRepas();
      }, [loading]);
      /* const tofindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) === index)
      const DupRepasDay = tofindDuplicates(RepasDay); */
if (loading) {
  if (ProgramExist){
    return(
      <div className='main'>
        <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
              <Row>
                <Col span={4}>
                  <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()} />
                </Col>
                <Col span={8} push={12}>
                  <Link
                    to={{pathname:`/makeprogram/${Id}`,
                    state: {
                      Id: Id,
                      Nr: Nr,
                      }
                    }}>
                    <Button type="primary" block>Modifier le programme diététique</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
              {RepasDay.map( (RepD, index) => (
                <div key={index}>
                <Divider orientation="left">Jour {RepD}</Divider>
                  <Row gutter={[16, 16]}>
                    {Repas[index].map((rep, index) => (
                      <Col span={8} key={index}>
                        <Link
                          to={{pathname:`/meal/${rep}`,
                          state: {Rep: rep}
                          }}>
                        <Card style={{ width: "100%" }} cover={<img style={{width:327, heigth:220}} alt={Foods.find(food => food.id == rep).foodName} src={Foods.find(food => food.id == rep).img}/>}>
                          <Meta title={Foods.find(food => food.id == rep).foodName} description={Foods.find(food => food.id == rep).calories+' Kcal'} />
                        </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>      
    )
    }
    return (
      <div className='main'>
        <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
              <Title level={4}>Il n'existe aucun programme</Title>
              <Link
                to={{pathname:`/makeprogram/${Id}`,
                state: {
                  Id: Id,
                  Nr: Nr,
                  }
                }}>
                <Button type="primary">Créer un programme diététique</Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
}
return <div className='main'>
    <div className="App">
        <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 24 }} >
                <div className="container-fluid">
                    <div className="CenterInside"><Spin /></div>
                </div>
            </Col>
        </Row>
    </div>
</div>;
};
  
export default DisplayProgram;