import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const AppStep11 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
        await delay(500);
        next();
      };
    return(
        <Form 
            name="step11"
            onChange={submitFormData}
        >
            <Title level={2}>Combien de fois par jour voulez-vous manger ?</Title>
            <Title level={5}>Nous planifierons vos repas selon vos préférences</Title>
            <Form.Item 
                name="nrepas"
                defaultValue={values.neppoids}
                onChange={handleFormData("nrepas")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="2-fois" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Deux fois (petit-déjeuner, dîner et 2 collations)</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="3-fois" />
                        <label htmlFor="control_02">
                                <Text strong>Trois fois (petit-déjeuner, déjeuner et dîner)</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="4-fois" />
                        <label htmlFor="control_03">
                                <Text strong>Quatre fois (petit-déjeuner, collation, déjeuner et dîner)</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_04" name="select" value="5-fois" />
                        <label htmlFor="control_04">
                                <Text strong>Cinq fois (petit-déjeuner, déjeuner, dîner et 2 collations)</Text>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="stqrt">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep11;