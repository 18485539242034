import React from 'react';
import axios from "axios";
import clientConfig from '../../../client-config';
import PostItem from './postItem';
import { Row, Col } from 'antd';

class AppBlog extends React.Component {

    constructor( props ) {
        super( props);
        this.state = {
            loading: false,
            posts : [],
            error: ''
        }
    }
    componentDidMount() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        var params = {
            url: `${wordPressSiteUrl}/wp-json/wp/v2/posts`,
            method: 'get',
            rejectUnauthorized: false,//add when working with https sites
            requestCert: false,//add when working with https sites
            agent: false,//add when working with https sites
        
          }
        this.setState( { loading: true }, () => {
            axios(params)
            .then( res=> {
                this.setState( {loading: false, posts: res.data})
            })
            .catch( error => this.setState( { loading: false, error: error.response.data.message}))
        })
    }
    render() {
        const {posts} = this.state;
        return (
            <div className='main'>
                <div className="App">
                    <Row id="feature" className="block featureBlock">
                        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
                            <div className="container-fluid">
                                <Row gutter={[16, 32]}>
                                { posts.map( post => (
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}  key={post.id} >
                                        <PostItem post={post}/> 
                                    </Col>      
                                ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default AppBlog;