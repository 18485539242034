import React from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Form, Button, Radio, Row, Col, Divider } from 'antd';

const EditLeadDaily = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const udaily ={
      Id: dataLead[0].Id,
      journeetype: dataLead[0].journeetype,
      mauxestomac: dataLead[0].mauxestomac,
      ideal_weight_reached: dataLead[0].ideal_weight_reached,
      tried_lose_weight: dataLead[0].tried_lose_weight,
      nrepas: dataLead[0].nrepas,
      entrainement_par_semaine: dataLead[0].entrainement_par_semaine,
      energie: dataLead[0].energie,
      eau: dataLead[0].eau,
      temppreprepas: dataLead[0].temppreprepas,
      alimentssurgeles: dataLead[0].alimentssurgeles,
    };
    const submitForm = () => {
      const wordPressSiteUrl = clientConfig.siteUrl;
      axios.post(`${wordPressSiteUrl}/wp-json/leads/udaily`, udaily)
        .then()
        .catch(err => {console.log(err.response.data.message)})
      handleOk();
    }
      return (
        <Form onFinish={submitForm} layout="vertical">
          <Form.Item
          label="Décrivez votre journée type"
          name="journeetype"
          value={udaily.journeetype}
          onChange={(e) => {udaily.journeetype = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].journeetype} size="small">
              <Radio.Button value="Sédentaire">La plupart du temps à la maison</Radio.Button>
              <Radio.Button value="Faible Actif">Au bureau</Radio.Button>
              <Radio.Button value="Actif">Longues promenades quotidiennes</Radio.Button>
              <Radio.Button value="Athlète">Travail physique</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Ressentez-vous des maux d'estomac pendant la journée ?"
            name="mauxestomac"
            value={udaily.mauxestomac}
            onChange={(e) => {udaily.mauxestomac = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].mauxestomac} size="small">
              <Radio.Button value="Oui">Oui</Radio.Button>
              <Radio.Button value="Non">Non</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Depuis combien de temps n'avez-vous pas atteint votre poids idéal ?"
            name="ideal_weight_reached"
            value={udaily.ideal_weight_reached}
            onChange={(e) => {udaily.ideal_weight_reached = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].ideal_weight_reached} size="small">
              <Radio.Button value="-1">Moins de 1 an</Radio.Button>
              <Radio.Button value="1-3">1-3 ans</Radio.Button>
              <Radio.Button value="+3">Plus de 3 ans</Radio.Button>
              <Radio.Button value="jamais">Je n'ai jamais atteint mon poids idéal</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Combien de fois avez-vous essayé de perdre du poids ?"
            name="tried_lose_weight"
            value={udaily.tried_lose_weight}
            onChange={(e) => {udaily.tried_lose_weight = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].tried_lose_weight} size="small">
              <Radio.Button value="Jamais">Jamais</Radio.Button>
              <Radio.Button value="1-2 fois">1-2 fois</Radio.Button>
              <Radio.Button value="beaucoup de tentatives">J'ai eu beaucoup de tentatives</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Nombre de repas par jour"
            name="nrepas"
            value={udaily.nrepas}
            onChange={(e) => {udaily.nrepas = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].nrepas}  size="small">
              <Radio.Button value="2-fois">Deux fois (petit-déjeuner, dîner et 2 collations)</Radio.Button>
              <Radio.Button value="3-fois">Trois fois (petit-déjeuner, déjeuner et dîner)</Radio.Button>
              <Radio.Button value="4-fois">Quatre fois (petit-déjeuner, collation, déjeuner et dîner)</Radio.Button>
              <Radio.Button value="5-fois">Cinq fois (petit-déjeuner, déjeuner, dîner et 2 collations)</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Nombre d'entrainements par semaine"
            name="entrainement_par_semaine"
            value={udaily.entrainement_par_semaine}
            onChange={(e) => {udaily.entrainement_par_semaine = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].entrainement_par_semaine}  size="small">
              <Radio.Button value="rien">Presque rien</Radio.Button>
              <Radio.Button value="promenades">Seulement des promenades</Radio.Button>
              <Radio.Button value="1-2-fois">1 à 2 fois par semaine</Radio.Button>
              <Radio.Button value="3-4-fois">3 à 4 fois par semaine</Radio.Button>
              <Radio.Button value="5-7-fois">5 à 7 fois par semaine</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Comment est votre énergie au jour le jour ?"
            name="energie"
            value={udaily.energie}
            onChange={(e) => {udaily.energie = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].energie} size="small">
              <Radio.Button value="Constant">Constant</Radio.Button>
              <Radio.Button value="Traîner avant les repas">Traîner avant les repas</Radio.Button>
              <Radio.Button value="Effondrement après le déjeuner">Effondrement après le déjeuner</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Combien d'eau buvez-vous quotidiennement ?"
            name="eau"
            value={udaily.eau}
            onChange={(e) => {udaily.eau = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].eau} size="small">
              <Radio.Button value="cafe-the">Café ou thé seulement</Radio.Button>
              <Radio.Button value="<2-verres">Moins de 2 verres</Radio.Button>
              <Radio.Button value="2-6-verres">2-6 verres</Radio.Button>
              <Radio.Button value="7-10-verres">7-10 verres</Radio.Button>
              <Radio.Button value=">10-verres">Plus de 10 verres</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Combien de temps êtes-vous prêt à consacrer à la préparation d'un repas ?"
            name="temppreprepas"
            value={udaily.temppreprepas}
            onChange={(e) => {udaily.temppreprepas = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].temppreprepas} size="small">
              <Radio.Button value="<30min">Moins de 30 minutes</Radio.Button>
              <Radio.Button value="30-60min">30-60 minutes</Radio.Button>
              <Radio.Button value=">1h">Plus d'une heure</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Vous aimeriez inclure des aliments surgelés ou prêts à cuire dans votre plan de repas?"
            name="alimentssurgeles"
            value={udaily.alimentssurgeles}
            onChange={(e) => {udaily.alimentssurgeles = e.target.value }}
          >
            <Radio.Group defaultValue={dataLead[0].alimentssurgeles} size="small">
              <Radio.Button value="Oui">Oui</Radio.Button>
              <Radio.Button value="Non">Non, Je préfère les plats cuisinés frais</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
                <Button key="back" onClick={handleCancel}>
                  Annuler
                </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
        </Form>
      )
    }
 export default EditLeadDaily;