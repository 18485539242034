import React, {useState} from"react";
import { Form, Row, Col, Typography, Button, Space, Checkbox, Switch } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    appareils: defaults
};
const AppStep20 = ({ next, prev, handleFormData, values }) => {
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const submitFormData = (e) => {
        if (switched === true) {
            values.appareils = JSON.stringify(defaults);
        }
        else if (switched === false){
            values.appareils = JSON.stringify(e.appareils);
        }
        onChange(e);
        next();
    }
    const onSwitch = (e) => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    return(
        <Form 
            name="step20"
            onFinish={submitFormData}
            initialValues={initValues}
        >
            <Title level={2}>Quels appareils de cuisine avez-vous ?</Title>
            <Title level={5}>Nous avons de nombreuses recettes pour tous les appareils de cuisine.</Title>
            <Form.Item
                name="appareils"
                defaultValue={values.appareils}
                onChange={handleFormData("appareils")}
                >
                    <CheckboxGroup
                        className="boxe"
                        style={{ width: '100%' }}
                        onChange={onChange}
                    >
                        <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Checkbox value="Mixeur" disabled={disabled}><Text>Mixeur</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Four" disabled={disabled}><Text>Four</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Micro onde" disabled={disabled}><Text>Micro onde</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Cuisinière" disabled={disabled}><Text>Cuisinière</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Pot instantané" disabled={disabled}><Text>Pot instantané</Text></Checkbox>
                        </Col>
                        </Row>
                    </CheckboxGroup>
                </Form.Item>
                <Row justify="end">
                    <Col xs={{ span: 10 }} sm={{ span: 11 }} lg={{ span: 8 }}>
                        <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
                    </Col>
                    <Col xs={{ span: 2 }} sm={{ span: 1 }} md={{ span: 1 }}>
                        <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
                            <Switch checked={switched} onChange={onSwitch} />
                        </Form.Item>
                    </Col>
              </Row>
                <br />
            
        
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep20;