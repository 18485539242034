import React, { useState } from "react";
import { Drawer, Button, Menu } from 'antd';
import { MailTwoTone, MenuOutlined, HomeOutlined, CoffeeOutlined, LogoutOutlined, LoginOutlined, AlertOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";


function Appheader() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const handleLogout = () => {
		localStorage.clear();
    onClose();
	};
  const menuItems = [
        {
            key: 'Accueil',
            icon: <HomeOutlined />,
            label: (<Link onClick={onClose} to="/">Accueil</Link>),

        },
        {
            key: 'blog',
            icon: <CoffeeOutlined />,
            label: (<Link onClick={onClose} to="/blog">Blog</Link>),
        },
        {
          key: 'quiz',
          icon: <AlertOutlined />,
          label: (<Link onClick={onClose} to="/quiz">Quiz</Link>),
        },
        {
            key: 'login',
            icon: <LoginOutlined />,
            label: (<Link onClick={onClose} to="/login">Se Connecter</Link>),
        },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: (<Link onClick={handleLogout} to="/">Se Déconnecter</Link>),
        },
    ];
    return (
      <div className="container-fluid">
        <div className="header">
            <a title="MealApp" className="logo" href="/"></a>
          <Button type="ghost" size="large" onClick={showDrawer}>
          <MenuOutlined />
          </Button>
          <Drawer
            placement="right"
            onClose={onClose }
            visible={visible}
          >
            <Menu
              defaultOpenKeys={['sub1']}
              mode="vertical"
              items={menuItems} 
            />
            <br></br>
                <h2><MailTwoTone />  contact@mail.com</h2>
          </Drawer>
        </div>
      </div>
      
        
    );
}

export default Appheader;