import React, { useLayoutEffect, useState } from 'react';
import { Col, Row, Divider, Button, notification } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import MakeProgramItem from './makeprogramitem';
import { ArrowLeftOutlined } from '@ant-design/icons'; 
import { useLocation } from 'react-router-dom';
import axios from "axios";
import clientConfig from '../../../../client-config';

const openNotification = () => {
  notification.open({
    message: 'Un programme a été créé',
    type: 'success',
    placement: 'top'
  });
};
const MakeProgram = () => {
  const location = useLocation();
  const { Id, Nr } = location.state
  const [progexist, SetProgExist] = useState(true)
  const wordPressSiteUrl = clientConfig.siteUrl;
  const authToken = clientConfig.siteToken;

  const current = new Date();
  const Current_Date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const formData = {
    Date_Program: Current_Date,
    Id_Lead: Id
  };
  
  const ProgExist = async() => {
    axios
    .get(`${wordPressSiteUrl}/wp-json/repas/idprogrambylead/${Id}`)
      .then((prog) => {
        if(Object.keys(prog.data).length === 0 || undefined){
          SetProgExist(false);
          console.log('prog existe', progexist)
        }
        else {SetProgExist(true)}
      })
      .catch((error) => {
        console.error(`onRejected program function called: ${error.message}`);
      });
      if(progexist === false) {
          CreateProgram();
          console.log('prog not existe', progexist)
        };
  }
  const CreateProgram = () => {
    axios.post(`${wordPressSiteUrl }/wp-json/program/i`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ authToken }`
      }
    })
      .then( (res) => {
        openNotification('success')
        SetProgExist(true)
      });
  }
  useLayoutEffect(() => {
      ProgExist()
  })

  return (
 <div className='main'>
  <div className="App">
    <Row id="feature" className="block featureBlock">
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()} >Retounez</Button>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={1}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={2}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={3}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={4}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={5}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={6}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={7}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={8}/>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={9}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={10}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={11}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={12}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={13}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={14}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={15}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={16}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={17}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={18}/>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={19}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={20}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={21}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={22}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={23}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={24}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={25}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={26}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={27}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={28}/>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={29}/>
      </Col><Divider />
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
        <MakeProgramItem RepasDay={30}/>
      </Col><Divider />
    </Row>
  </div>
 </div>
  )

}
export default MakeProgram;