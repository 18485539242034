import React from "react";
import { Form, Row, Col, Typography, InputNumber, Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { number } from "prop-types";


const { Title } = Typography;

const AppStep3 = ({ next, prev, handleFormData, values }) => {
    const submitFormData = (e) => {
        next();
      };
    
    return(
    <Form 
        name="step3"
        onFinish={submitFormData}
        labelCol={{ span: 8, }}
        wrapperCol={{ span: 16, }}
      >
            <Title level={2}>Ok, quel est votre poids désiré?</Title>
            <br />
            <Form.Item
              label="Poids désiré"
              name="poidssouhaite"
              defaultValue={values.poidssouhaite}
              onChange={handleFormData("poidssouhaite")}
              rules={[
              {
                required: true,
                message: 'Entrez votre poids souhaité s\'il vous plaît!',
              },
            ]}>
                <InputNumber type="number" precision={number} size="large" addonAfter='kg' placeholder='00' min={40} max={250} style={{width: '100%',}}/>
            </Form.Item>
            
        <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button type="primary" shape="circle" onClick={prev} icon={<ArrowLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
        </Row>
    </Form>
    );
}

export default AppStep3;