import React from 'react';
import Salespermonth from './dashboard/salespermonth';
import MaleFemalePie from './dashboard/malefemalepie';
import ConversionRate from './dashboard/conversionrate';
import PlansTable from './dashboard/planstable';
import { Row, Col, Input, Card, Typography, Divider, Statistic } from 'antd';
import { getUserName } from "../functions";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import LeadsNumber from './dashboard/leadsnumber';

const { Search } = Input;
const { Title } = Typography;
const Dashboard = () => {
    const onSearch = (value) => console.log(value);
    const userName = ( getUserName() ) ? getUserName() : '';


    return (
        <div className='main'>
          <div className="App">
            <Row id="feature" className="block featureBlock">
              <Col span={24} >
                <div className="container-fluid">
                  
                  <Row gutter={[16, 24]} justify="center">
                    <Col span={6}>
                      <LeadsNumber />
                    </Col>
                    <Col span={6}>
                      <Card bordered={false} className="gx-card gx-card-success">
                        <div className="number">
                        <Row align="middle">
                            <Col xs={24}>
                                <Title level={4} className='gx-title4'>Plans</Title>
                            </Col>
                            <Col xs={15}>
                              <Title level={3} className='gx-title2'>177</Title>
                            </Col>
                            <Col xs={9}>
                            <Statistic
                                value={5}
                                precision={1}
                                valueStyle={{
                                  color:'#cf1322'
                                }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                              />
                            </Col>
                        </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                    <Card bordered={false} className="gx-card gx-card-purple">
                        <div className="number">
                        <Row align="middle">
                            <Col xs={24}>
                                <Title level={4} className='gx-title4'>Visits</Title>
                            </Col>
                            <Col xs={15}>
                              <Title level={3} className='gx-title2'>12877</Title>
                            </Col>
                            <Col xs={9}>
                              <Statistic
                                  value={7.22}
                                  precision={1}
                                  valueStyle={{
                                    color:'#3f8600'
                                  }}
                                  prefix={<ArrowUpOutlined />}
                                  suffix="%"
                                />
                            </Col>
                        </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                    <Card bordered={false} className="gx-card gx-card-magenta">
                        <div className="number">
                        <Row align="middle">
                            <Col xs={24}>
                                <Title level={4} className='gx-title4'>Payments</Title>
                            </Col>
                            <Col xs={15}>
                              <Title level={3} className='gx-title2'>1877 Dh</Title>
                            </Col>
                            <Col xs={9}>
                            <Statistic
                                value={9.3}
                                precision={1}
                                valueStyle={{
                                  color:'#cf1322'
                                }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                              />
                            </Col>
                        </Row>
                        </div>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <div className="bar-chart">
                      <Title level={4} className='gx-title4'>Chart des leads :</Title>
                        <Salespermonth />
                      </div>
                      
                    </Col>
                    <Col span={8} >
                      <div className="bar-chart">
                      <Title level={4} className='gx-title4'>Plans par sexe :</Title>
                        <MaleFemalePie />
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="bar-chart">
                        <Title level={4} className='gx-title4'>Taux de conversion :</Title>
                        <ConversionRate />
                      </div>
                    </Col>

                    <Col span={24}>
                        <PlansTable />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
    )
}

export default Dashboard