import React from 'react';
import Foods from '../../../../assets/food2.json';
import { Row, Col, Image, Typography, Space, Button, Divider } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'; 
import { useLocation } from 'react-router-dom';

const DisplayMeal = () => {
    const location = useLocation()
    const { Rep } = location.state
    const { Title, Text, Paragraph } = Typography;
return(
    <div className='main'>
      <div className="App">
        <Row id="feature" className="block featureBlock">
        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()} >Retounez</Button>
        </Col><Divider />
          <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10}}>
                    <Image
                        width={"100%"}
                        height
                        preview={false}
                        src={Foods[Rep].img}
                    />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14}}>
                  <Space direction="vertical">
                    <Title level={4}>{Foods[Rep].foodName} Kcal</Title>
                    <Text><Text strong>Calories : </Text>{Foods[Rep].calories} Kcal</Text>
                    <Text><Text strong>Lipides : </Text>{Foods[Rep].lipide} Gr</Text>
                    <Text><Text strong>Glucides : </Text>{Foods[Rep].glucide} Gr</Text>
                    <Text><Text strong>Protéines : </Text>{Foods[Rep].protein} Gr</Text>
                    
                  </Space>
                </Col>
            </Row>
          </Col>
          <Divider />
          <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
            <Title level={5}>Descriptions</Title>
            <Paragraph>{Foods[Rep].description}</Paragraph>
          </Col>
        </Row>
      </div>
    </div>  
)
}
export default DisplayMeal