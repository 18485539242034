import React, { useState, useEffect } from 'react';
import axios from "axios";
import clientConfig from '../../client-config';
import { KeyOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Typography, Alert } from 'antd';
import { Redirect , useLocation } from 'react-router-dom';
const { Title } = Typography;

const SetNewPassword = () => {
  const location = useLocation()
  const { mail } = location.state
  const [cod, setCod] = useState('');
  const [pass, setPass] = useState('')
  const [param, setParam] = useState({
        email: mail,
        code: '',
        password: ''
    });
  const [msg, setMsg] = useState('');
  const [errorstatus, setErrorStatus] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [views, setViews] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(false);
  const [isInitialRender2, setIsInitialRender2] = useState(false);
  useEffect(() => {
    getParams();
    if (isInitialRender) {
        
        console.log(param, msg, errorstatus)
        if (isInitialRender2) {
          PostParams()
        }
        setViews(views + 1);
        setIsInitialRender(false);
      }
  }, [views, isInitialRender]);
  const getParams = () => {
    setParam({email: mail, code:cod, password:pass})
    setIsInitialRender2(true)
  }
  const PostParams = () => {
    const wordPressSiteUrl = clientConfig.siteUrl;
    axios.post(`${wordPressSiteUrl}/wp-json/bdpwr/v1/set-password`, param)
      .then(res => {
        setMsg(res.data.data.status)
        setErrorStatus('')
        setTimeout(() => setRedirect(true), 1000)
      })
      .catch(err => {
        setErrorStatus(err.response.data.data.status)
        setMsg('')
      })
  }
  const submitForm = () => {
    setIsInitialRender(true);
    PostParams();
  }
    
    return (
        <div className='main'>
        <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }} >
              <div className="container-fluid">
              <Title level={2}>Définir un nouveau mot de passe</Title>
              <br />
              <Form
                name="SetNewPassword"
                className="login-form"
                onFinish={submitForm}
                block
              >
                <Form.Item
                  onChange={(e) => {setCod(e.target.value)}}
                  rules={[
                  {
                    required: true,
                    message: 'Veuillez saisir votre code de réinitialisation!',
                  },
                  ]}
                >
                  <Input 
                    name="code"
                    prefix={<KeyOutlined className="site-form-item-icon" />}
                    placeholder="Code de réinitialisation" />
                </Form.Item>
                <Form.Item
                  name="password"
                  onChange={(e) => {setPass(e.target.value)}}
                  checkedvalues={param.password}
                  rules={[{ type: "password",
                  required: true,
                  message: 'Veuillez saisir un nouveau mot de passe s\'il vous plaît!',}]}
                  hasFeedback>
                  <Input.Password
                    name="password"
                    placeholder="Entrez un mot de passe:"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmer"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                  {
                    required: true,
                    message: 'Veuillez confirmer votre mot de passe !',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Les deux mots de passe que vous avez saisis ne correspondent pas!'));
                },
              }),
            ]}
          >
            <Input.Password 
              placeholder="Confirmez le mot de passe"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
            </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block>Envoyer</Button>
          </Form.Item>
        </Form>

        {errorstatus === 400  && (<Alert message="Vous devez fournir un code." type="error" />)}
        {errorstatus === 500  && (<Alert message="Vous devez demander un code de réinitialisation de mot de passe avant d'essayer de définir un nouveau mot de passe." type="error" />)}
        {msg === 200  && (<Alert message="Mot de passe réinitialisé avec succès" type="info" />)}
        {redirect && (<Redirect to={'/login'}/>)}
              
      </div>
    </Col>
  </Row>
 </div>
</div>
    )
}

export default SetNewPassword