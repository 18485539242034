import React from "react";
import axios from "axios";
import clientConfig from "../../../client-config";
import { Button, Input, Form, Typography, Row, Space, Col } from 'antd';
import { MailOutlined, CaretLeftOutlined } from '@ant-design/icons';
const base64 = require('base-64');
const { Title } = Typography;

const AppEmail = ({ next, prev, handleFormData, values }) => {
    const submitFormData = async () => {
        const formData = {
            email: values.email,
            perdrepoids: values.perdrepoids,
            updates: values.updates,
            sexe: values.sexe,
            poidssouhaite: values.poidssouhaite,
            age: values.age,
            taille: values.taille,
            poidsactuel: values.poidsactuel,
            bodytype: values.bodytype,
            journeetype: values.journeetype,
            mauxestomac: values.mauxestomac,
            ideal_weight_reached: values.ideal_weight_reached,
            tried_lose_weight: values.tried_lose_weight,
            nrepas: values.nrepas,
            vraisofaux: values.vraisofaux,
            entrainement_par_semaine: values.entrainement_par_semaine,
            energie: values.energie,
            dormir: values.dormir,
            eau: values.eau,
            temppreprepas: values.temppreprepas,
            alimentssurgeles: values.alimentssurgeles,
            appareils: values.appareils,
            allergies: values.allergies,
            legumes: values.legumes,
            cereales: values.cereales,
            produits: values.produits,
            viande: values.viande,
            fruits: values.fruits,
        };
        const formUser = {
            login: values.email,
            username: values.email,
            email: values.email,
            password: values.password,
            name: values.email,
            roles: ["subscriber"]
        };
        const wordPressSiteUrl = clientConfig.siteUrl;
        const authToken = clientConfig.siteToken;
        const username = clientConfig.username;
        const password = clientConfig.password;
         // Post request to create a post
        await axios.post( `${ wordPressSiteUrl }/wp-json/leads/i/`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .catch(err => {console.log(err.response.data.message)})
        
        await axios.post(`${wordPressSiteUrl}/wp-json/wp/v2/users`, formUser, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + base64.encode(username + ":" + password),
            }
        })
            .catch(err => {console.log(err.response.data.message)})
        next();
    };
  
    return(
        <Form 
            name="email"
            onFinish={submitFormData}
        >
            <Title level={2}>Entrez votre adresse E-mail :</Title>
              
                <Form.Item
                    name="email"
                    checkedvalues={values.email}
                    onChange={handleFormData("email")}
                    rules={[{ type: "email",
                    required: true,
                    message: 'Entrez votre adresse E-mail s\'il vous plaît!',}]}>
                        <Input size="large" prefix={<MailOutlined />}/>
                </Form.Item>
                <br />
                <Title level={2}>Entrez un mot de passe  pour votre compte:</Title>
        <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                  name="password"
                  label="Entrez un mot de passe:"
                  checkedvalues={values.password}
                  onChange={handleFormData("password")}
                  rules={[{ type: "password",
                  required: true,
                  message: 'Veuillez saisir votre mot de passe s\'il vous plaît!',}]}
                  hasFeedback>
                     <Input.Password />
              </Form.Item>
             </Col>
             <Col span={12}>
              <Form.Item
                    name="confirmer"
                    label="Confirmez le mot de passe"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Veuillez confirmer votre mot de passe !',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error('Les deux mots de passe que vous avez saisis ne correspondent pas!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    );
}
export default AppEmail;