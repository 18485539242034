import React from "react";
import { Row, Col, Typography, Image, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import AppImc from "../imc";
import After_first_week from './img/After_first_week.svg'; import Calories from './img/Calories.svg';
import Water from './img/Water.svg'; import Duration from './img/Duration.svg';
import Age from './img/Age.svg'; import Height from './img/Height.svg';
import Yes_you_can from './img/Yes_you_can.svg'; import How_it_works from './img/How_it_works.svg'
import AppChart from "../chart";
import AppDcn from "./dcn";

const { Title, Text, Paragraph } = Typography;
const Final = ({ values }) => {
    //destructuring the object from values
    const { perdrepoids, poidssouhaite, age, taille, poidsactuel } = values;
    let gain = parseInt(poidssouhaite) - parseInt(poidsactuel);
  return (
    <>
    <br />
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
            <Title level={2}>Prévisions en fonction de vos réponses</Title>
            <Row gutter={[40, 8]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="results">
                {perdrepoids === "Oui" &&
                  <Title level={4}>Prévision de perdre de poids {parseInt(gain)} Kg</Title>
                }
                {perdrepoids === "Non" &&
                  <Title level={4}>Prévision de prise de poids {parseInt(gain)} Kg</Title>
                }
                <AppChart values={values} />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="resultsblock">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="results">
                  <Row gutter={20}>
                    <Col xs={{ span: 6 }} md={{ span: 4}}>
                      <Image width={60} src={After_first_week} preview={false}/>
                    </Col>
                    <Col xs={{ span: 18 }} md={{ span: 20}}>
                      <Text>Après la première semaine</Text>
                      <Title level={3}>{parseInt(gain)/4} Kg</Title>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="results">
                  <Row gutter={20}>
                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 4}}>
                      <Image width={60} src={Water} preview={false}/>
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 20}}>
                      <Text>Minimum d'eau quotidien</Text>
                      <Title level={3}>2.1 L</Title>
                    </Col>
                  </Row>
                </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 4}}>
                    <Image width={60} src={Calories} preview={false}/>
                  </Col>
                  <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 20}}>
                    <Text>Norme calorique quotidienne</Text>
                    <AppDcn  values={values} />
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="results">
                <Row gutter={20}>
                  <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 4}}>
                    <Image width={60} src={Duration} preview={false}/>
                  </Col>
                  <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 20}}>
                    <Text>Premier résultat visible en</Text>
                    <Title level={3}>10 jours</Title>
                  </Col>
                </Row>
              </Col>
              </Col>
            </Row>
            <Title level={2}>Résumé de votre profil</Title>
            <Row gutter={[40, 8]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="results">
              <AppImc values={values} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} className="resultsblock">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="results">
                <Row gutter={20}>
                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 4}}>
                      <Image width={60} src={Age} preview={false}/>
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 20}}>
                      <Text>Age</Text>
                      <Title level={3}>{age} ans</Title>
                    </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24}} className="results">
                <Row gutter={20}>
                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 4}}>
                      <Image width={60} src={Height} preview={false}/>
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 20}}>
                      <Text>Hauteur / Poids</Text>
                      <Title level={3}>{taille} cm / {poidsactuel} kg</Title>
                    </Col>
                </Row>
              </Col>
            </Col>
            </Row>
          </Col>
        </Row>  
      </div>
      <Row gutter={[40, 40]} className="block bgOrange">
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
      <Row id="feature" className="block featureBlock">
        <Col span={12}>
          <Image width={270} src={Yes_you_can} preview={false}/>
        </Col>
        <Col span={12}>
          <Title level={2}>Oui, vous pouvez!</Title>
          <Title level={4}> 83 % de personnes similaires ont pris plus de {parseInt(gain)} Kg avec notre plan</Title>
        </Col>
        </Row>
        </Col>
      </Row>
      <div className="App">
      <Row id="feature" className="block featureBlock">
        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
         <Row gutter={[40, 40]}>
          <Col span={14}>
            <Title level={2}> Réduisez le volume des zones problématiques suivantes :</Title><br />
            <Row gutter={[40, 40]} >
              <Col span={8}>
                <CheckCircleFilled style={{ fontSize: '30px', color: '#e45c30', verticalAlign: 'middle', marginRight: '6px' }}/> <Text strong>Menton</Text>
              </Col>
              <Col span={8}>
                <CheckCircleFilled style={{ fontSize: '30px', color: '#e45c30', verticalAlign: 'middle', marginRight: '6px' }}/> <Text strong>Bras</Text>
              </Col>
              <Col span={8}>
                <CheckCircleFilled style={{ fontSize: '30px', color: '#e45c30', verticalAlign: 'middle', marginRight: '6px' }}/> <Text strong>Bout</Text>
              </Col>
              <Col span={8}>
                <CheckCircleFilled style={{ fontSize: '30px', color: '#e45c30', verticalAlign: 'middle', marginRight: '6px' }}/> <Text strong>Coffre</Text>
              </Col>
              <Col span={8}>
                <CheckCircleFilled style={{ fontSize: '30px', color: '#e45c30', verticalAlign: 'middle', marginRight: '6px' }}/> <Text strong>Ventre</Text>
              </Col>
              <Col span={8}>
                <CheckCircleFilled style={{ fontSize: '30px', color: '#e45c30', verticalAlign: 'middle', marginRight: '6px' }}/> <Text strong>Jambes</Text>
              </Col>
              <Col span={24}>
                <Paragraph>
                La graisse viscérale entoure vos organes et provoque un volume corporel supplémentaire. Ce type de graisse est le plus dangereux car il affecte non seulement votre forme, mais aussi votre santé et votre équilibre hormonal.
                </Paragraph>
                <Paragraph>
                Cependant, nous avons déjà suggéré un plan de repas personnalisé qui peut déclencher à la fois une perte de graisse sous-cutanée et viscérale.
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Image width={270} src={How_it_works} preview={false}/>
          </Col>
         </Row>
        </Col>
        </Row>
        <Row id="feature" className="block fixedbottom ">
          <Col xs={{ span: 16, offset: 4 }} sm={{ span: 12, offset: 6 }} md={{ span: 6, offset: 9 }} >
            <Button type="primary" shape="round" size="large" block><Text strong style={{color: '#fff'}}>Obtenir mon plan</Text></Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Final;
