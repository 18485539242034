import React from "react";
import { Form, Row, Col, Typography, Image, Button, Space, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import yes from './img/yes.svg';
import no from './img/no.svg';

const { Title, Text } = Typography;

const AppStep8 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
        await delay(500);
        next();
      };
    return(
        <Form 
            name="step8"
            onChange={submitFormData}
        >
            <Title level={2}>Ressentez-vous des maux d'estomac pendant la journée ?</Title>
            <Form.Item 
                name="mauxestomac"
                defaultValue={values.mauxestomac}
                onChange={handleFormData("mauxestomac")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="Oui" />
                        <label className="bodytypelbl" htmlFor="control_01">
                        <Row gutter={[8, 8]}>
                            <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Oui</Text>
                            </Col>
                            <Col xs={{ span: 2}} sm={{ span: 2 }} md={{ span: 2 }}>
                                <Image width={20} src={yes} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="Non" />
                        <label htmlFor="control_02">
                        <Row gutter={[8, 8]}>
                            <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }}>
                                <Text strong>Non</Text>
                            </Col>
                            <Col xs={{ span: 2}} sm={{ span: 2 }} md={{ span: 2 }}>
                                 <Image width={20} src={no} preview={false}/>
                            </Col>
                        </Row>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />} />
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep8;