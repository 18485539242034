import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Checkbox, Form, Button, Typography, Row, Col, Divider, Switch, Image } from 'antd';
import Avocado from '../../../home/steps/img/Avocado.svg'; import Haricots from '../../../home/steps/img/Haricots.svg';
import Champignons from '../../../home/steps/img/Champignons.svg'; import Oeufs from '../../../home/steps/img/Oeufs.svg';
import Lait from '../../../home/steps/img/Lait.svg'; import Fromageblanc from '../../../home/steps/img/Fromageblanc.svg';
import Tofu from '../../../home/steps/img/Tofu.svg'; import Hoummous from '../../../home/steps/img/Hoummous.svg';
import Laitvegetal from '../../../home/steps/img/Laitvegetal.svg';
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    produits: defaults
};
const EditLeadProduits = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    const uproduits ={
        Id: dataLead[0].Id,
        produits: dataLead[0].produits,
      };
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const onSwitch = () => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    const submitForm = (e) => {
        if (switched === true) {
            uproduits.produits = JSON.stringify(defaults);
        }
        else if (switched === false){
            uproduits.produits = JSON.stringify(e.produits);
        }
        onChange(e);
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/uproduits`, uproduits)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }
    return(
      <Form onFinish={submitForm} initialValues={initValues} >
        <Form.Item
          name="produits"
          value={uproduits.produits}
          onChange={(e) => {uproduits.produits = e.target.value }}
        >
          <CheckboxGroup
            className="boxe"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Checkbox value="Avocado" disabled={disabled}><Text><Image width={18} src={Avocado} preview={false}/> Avocado</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Haricots" disabled={disabled}><Text><Image width={18} src={Haricots} preview={false}/> Haricots</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Champignons" disabled={disabled}><Text><Image width={18} src={Champignons} preview={false}/> Champignons</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Des œufs" disabled={disabled}><Text><Image width={18} src={Oeufs} preview={false}/> Des œufs</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Du lait" disabled={disabled}><Text><Image width={18} src={Lait} preview={false}/> Du lait</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Fromage blanc" disabled={disabled}><Text><Image width={18} src={Fromageblanc} preview={false}/> Fromage blanc</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Tofu" disabled={disabled}><Text><Image width={18} src={Tofu} preview={false}/> Tofu</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Hoummous" disabled={disabled}><Text><Image width={18} src={Hoummous} preview={false}/> Hoummous</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Lait végétal" disabled={disabled}><Text><Image width={18} src={Laitvegetal} preview={false}/> Lait végétal</Text></Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        
        <Row justify="end">
          <Col xs={{ span: 9 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>
            <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
          </Col>
          <Col span={2}>
            <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
              <Switch checked={switched} onChange={onSwitch} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadProduits;