import React from "react";
import { Form, Row, Col, Typography, Button, Space, Radio } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const AppStep16 = ({ next, prev, handleFormData, values }) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const submitFormData = async (e) => {
          await delay(500);
        next();
      };
    return(
        <Form 
            name="step16"
            onChange={submitFormData}
        >
            <Title level={2}>Combien dormez-vous ?</Title>
            <Form.Item 
                name="dormir"
                defaultValue={values.dormir}
                onChange={handleFormData("dormir")}
                rules={[
                    {
                    required: true,
                    message: 'Choisissez s\'il vous plaît!',
                    },
            ]}>
                <section className="typicalday">
                <Radio.Group>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_01" name="select" value="<5" />
                        <label className="bodytypelbl" htmlFor="control_01">
                                <Text strong>Moins de 5 heures</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_02" name="select" value="5-6" />
                        <label htmlFor="control_02">
                                <Text strong>5-6 heures</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_03" name="select" value="7-8" />
                        <label htmlFor="control_03">
                                <Text strong>7-8 heures</Text>
                        </label>
                    </div>
                    <div className="typicaldaydiv">
                        <input type="radio" id="control_04" name="select" value=">8" />
                        <label htmlFor="control_04">
                                <Text strong>Plus de 8 heures</Text>
                        </label>
                    </div>
                    </Radio.Group>
                </section>
            </Form.Item>

            <Row justify="start">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    {/* <Button type="primary" htmlType="submit">
                        Continue
                    </Button> */}
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep16;