import React, {useState} from "react";
import { Form, Row, Col, Typography, Button, Space, Image, Checkbox, Switch } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import Brocoli from './img/Broccoli.svg'; import Cauliflower from './img/Cauliflower.svg';
import Bellpepper from './img/Bellpepper.svg'; import Eggplant from './img/Eggplant.svg';
import Cabbage from './img/Cabbage.svg'; import Asparagus from './img/Asparagus.svg';
import Onion from './img/Onion.svg'; import Spinach from './img/Spinach.svg';
import Tomato from './img/Tomato.png'; import Cucumber from './img/Cucumber.png';
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    legumes: defaults
};
const AppStep22 = ({ next, prev, handleFormData, values }) => {
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const submitFormData = (e) => {
        if (switched === true) {
            values.legumes = JSON.stringify(defaults);
        }
        else if (switched === false){
            values.legumes = JSON.stringify(e.legumes);
        }
        onChange(e);
        next();
    }
    const onSwitch = (e) => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    return(
        <Form 
            name="step22"
            onFinish={submitFormData}
            initialValues={initValues}
        >
            <Title level={2}>Cochez les légumes que vous souhaitez inclure :</Title>
            
            <Form.Item
                name="legumes"
                defaultValue={values.legumes}
                onChange={handleFormData("legumes")}
                >
                    <CheckboxGroup
                        className="boxe"
                        style={{ width: '100%' }}
                        onChange={onChange}
                    ><Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Checkbox value="Brocoli" disabled={disabled}><Image width={18} src={Brocoli} preview={false}/> <Text>Brocoli</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Choufleur" disabled={disabled}><Image width={18} src={Cauliflower} preview={false}/> <Text>Chou-fleur</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Poivron" disabled={disabled}><Image width={18} src={Bellpepper} preview={false}/> <Text>Poivron</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Aubergine" disabled={disabled}><Image width={18} src={Eggplant} preview={false}/> <Text>Aubergine</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Chou" disabled={disabled}><Text><Image width={18} src={Cabbage} preview={false}/> Chou</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Asperges" disabled={disabled}><Image width={18} src={Asparagus} preview={false}/> <Text>Asperges</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Epinard" disabled={disabled}><Image width={18} src={Spinach} preview={false}/> <Text>Epinard</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Oignon" disabled={disabled}><Image width={18} src={Onion} preview={false}/> <Text>Oignon</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Tomate" disabled={disabled}><Image width={18} src={Tomato} preview={false}/> <Text>Tomate</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Concombre" disabled={disabled}><Image width={18} src={Cucumber} preview={false}/> <Text>Concombre</Text></Checkbox>
                        </Col>
                        </Row>
                
                </CheckboxGroup>
                </Form.Item>
                <Row justify="end">
                    <Col xs={{ span: 10 }} sm={{ span: 11 }} md={{ span: 11 }} lg={{ span: 9 }} xl={{ span: 7 }}>
                        <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
                    </Col>
                    <Col xs={{ span: 2 }} sm={{ span: 1 }} md={{ span: 1 }}>
                        <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
                            <Switch checked={switched} onChange={onSwitch} />
                        </Form.Item>
                    </Col>
              </Row>
                <br />
        
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} icon={<CaretLeftOutlined />} />
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    )
}
export default AppStep22;