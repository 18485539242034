import React from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Divider, Form, Button, InputNumber, Row, Col, Radio } from 'antd';
import { number } from "prop-types";

const EditLeadMeasurments = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const umesures ={
        Id: dataLead[0].Id,
        age: dataLead[0].age,
        taille: dataLead[0].taille,
        poidsactuel: dataLead[0].poidsactuel,
        poidssouhaite: dataLead[0].poidssouhaite,
        bodytype: dataLead[0].bodytype,
      };
    const submitForm = () => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/umeasurments`, umesures)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }

    return(
    <Form onFinish={submitForm} labelCol={{ span: 6}} wrapperCol={{ span: 18 }} layout="horizontal">
      <Form.Item 
        label="Age"
        name="age"
        value={dataLead[0].age}
        onChange={(e) => {umesures.age =  e.target.value }}
        rules={[
          {
            required: true,
            message: 'Entrez votre age s\'il vous plaît!',
          },
        ]}>
        <InputNumber type="number" precision={number} size='large' addonAfter='ans' placeholder={dataLead[0].age} min={12} max={100} style={{width: '100%',}} />
      </Form.Item>
      <Form.Item 
        label="Taille"
        name="taille"
        value={dataLead[0].taille}
        onChange={(e) => {umesures.taille =  e.target.value }}
        rules={[
          {
            required: true,
            message: 'Entrez votre Taille s\'il vous plaît!',
          },
        ]}>
        <InputNumber type="number" precision={number} size='large' addonAfter='cm' placeholder={dataLead[0].taille} min={120} max={220} style={{width: '100%',}} />
      </Form.Item>
      <Form.Item 
        label="Poids actuel"
        name="poidsactuel"
        value={dataLead[0].poidsactuel}
        onChange={(e) => {umesures.poidsactuel =  e.target.value }}
        rules={[
          {
            required: true,
            message: 'Entrez votre Poids actuel s\'il vous plaît!',
          },
        ]}>
        <InputNumber type="number" precision={number} size='large' addonAfter='kg' placeholder={dataLead[0].poidsactuel} min={40} max={250} style={{width: '100%',}} />
      </Form.Item>
      <Form.Item
        label="Poids désiré"
        name="poidssouhaite"
        value={dataLead[0].poidssouhaite}
        onChange={(e) => {umesures.poidssouhaite =  e.target.value }}
        rules={[
        {
          required: true,
          message: 'Entrez votre poids souhaité s\'il vous plaît!',
        },
      ]}>
        <InputNumber type="number" precision={number} size="large" addonAfter='kg' placeholder={dataLead[0].poidssouhaite} min={40} max={250} style={{width: '100%',}}/>
      </Form.Item>
      <Form.Item
          label="Type de corps"
          name="bodytype"
          value={umesures.bodytype}
          onChange={(e) => {umesures.bodytype =  e.target.value }}
        >
          <Radio.Group defaultValue={dataLead[0].bodytype}>
            <Radio.Button value="Ectomorphe">Ectomorphe</Radio.Button>
            <Radio.Button value="Mésomorphe">Mésomorphe</Radio.Button>
            <Radio.Button value="Endomorphe">Endomorphe</Radio.Button>
          </Radio.Group>
        </Form.Item>
      <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
                <Button key="back" onClick={handleCancel}>
                  Annuler
                </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
    </Form>
    );
}
export default EditLeadMeasurments;