import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clientConfig from '../../../client-config';
import { Column } from '@ant-design/plots';

const Salespermonth = () => {
  const [CMlength, setCMlength] = useState([]);
  const [PMlength, setPMlength] = useState([]);
  const [PPMlength, setPPMlength] = useState([]);
  const [PPPMlength, setPPPMlength] = useState([]);
  const [PPPPMlength, setPPPPMlength] = useState([]);
  const wordPressSiteUrl = clientConfig.siteUrl;
  axios.get(`${wordPressSiteUrl}/wp-json/leads/all/datetime`)
      .then((resp) => {setCMlength(resp.data.length)})
      .catch(err => {console.log(err.response.data.message)})
  axios.get(`${wordPressSiteUrl}/wp-json/leads/all/datetimelm`)
      .then((resp) => {setPMlength(resp.data.length)})
      .catch(err => {console.log(err.response.data.message)})
  axios.get(`${wordPressSiteUrl}/wp-json/leads/all/datetimellm`)
      .then((resp) => {setPPMlength(resp.data.length)})
      .catch(err => {console.log(err.response.data.message)})
  axios.get(`${wordPressSiteUrl}/wp-json/leads/all/datetimelllm`)
      .then((resp) => {setPPPMlength(resp.data.length)})
      .catch(err => {console.log(err.response.data.message)})
  axios.get(`${wordPressSiteUrl}/wp-json/leads/all/datetimelllm`)
      .then((resp) => {setPPPPMlength(resp.data.length)})
      .catch(err => {console.log(err.response.data.message)})
  const month = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"] ;
  const d = new Date();
  const CM = month[d.getMonth()]; let PM = month[d.getMonth() - 1]; let PPM = month[d.getMonth() - 2]; const PPPM = month[d.getMonth() - 3]; const PPPPM = month[d.getMonth() - 4];
  const data = [
    {
      mois: PPPPM,
      Plans: PPPPMlength,
    },
    {
      mois: PPPM,
      Plans: PPPMlength,
    },
    {
      mois: PPM,
      Plans: PPMlength,
    },
    {
      mois: PM,
      Plans: PMlength,
    },
    {
      mois: CM,
      Plans: CMlength,
    },
  ];
  const config = {
    data,
    xField: 'mois',
    yField: 'Plans',
    height: 300,
    label: {
      position: 'middle',
      style: {
        fill: '#fff',
        opacity: 0.9,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      sales: {
        alias: 'Plans',
      },
    },
  };
  return <Column {...config} />;
};

export default Salespermonth;
