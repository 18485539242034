import React, { useState } from 'react';
import axios from 'axios';
import clientConfig from '../../../client-config';
import { Pie, G2 } from '@ant-design/plots';

const MaleFemalePie = () => {
  const [SMlength, setSMlength] = useState([]);
  const [SFlength, setSFlength] = useState([]);
  const wordPressSiteUrl = clientConfig.siteUrl;
  axios.get(`${wordPressSiteUrl}/wp-json/leads/sex/homme`)
      .then((res1) => {setSMlength(res1.data.length)})
      .catch(err => {console.log(err.response.data.message)})
  axios.get(`${wordPressSiteUrl}/wp-json/leads/sex/femme`)
      .then((res2) => {setSFlength(res2.data.length)})
      .catch(err => {console.log(err.response.data.message)})
      
  const G = G2.getEngine('canvas');
  const data = [
    {
      sex: 'Mâle',
      sold: SMlength,
    },
    {
      sex: 'Femelle',
      sold: SFlength,
    },
  ];
  const config = {
    appendPadding: 10,
    height: 300,
    data,
    angleField: 'sold',
    colorField: 'sex',
    radius: 0.66,
    color: ['#1890ff', '#f04864'],
    label: {
      content: (obj) => {
        const group = new G.Group({});
        group.addShape({
          type: 'image',
          attrs: {
            x: 0,
            y: 0,
            width: 30,
            height: 38,
            img:
              obj.sex === 'Mâle'
                ? 'https://gw.alipayobjects.com/zos/rmsportal/oeCxrAewtedMBYOETCln.png'
                : 'https://gw.alipayobjects.com/zos/rmsportal/mweUsJpBWucJRixSfWVP.png',
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};

export default MaleFemalePie
