import React from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Input, Form, Button, Radio, Row, Col, Divider } from 'antd';
import { MailOutlined } from '@ant-design/icons';


const EditLeadBasic = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
  const ubasic ={
    Id: dataLead[0].Id,
    email: dataLead[0].email,
    perdrepoids: dataLead[0].perdrepoids,
    sexe: dataLead[0].sexe,
  };
  const submitForm = () => {
    const wordPressSiteUrl = clientConfig.siteUrl;
    axios.post(`${wordPressSiteUrl}/wp-json/leads/ubasic`, ubasic)
      .then()
      .catch(err => {console.log(err.response.data.message)})
    handleOk();
  }
    return (
      <Form onFinish={submitForm} labelCol={{ span: 10}} wrapperCol={{ span: 14 }} layout="horizontal" >
        <Form.Item label="Adresse E-mail" name="email" disabled defaultValue={dataLead[0].email} >
          <Input prefix={<MailOutlined />} disabled placeholder={dataLead[0].email}/>
        </Form.Item>
        <Form.Item
          label="Voulez vous perdre du poids?"
          name="perdrepoids"
          value={ubasic.perdrepoids}
          onChange={(e) => {ubasic.perdrepoids =  e.target.value }}
        >
          <Radio.Group defaultValue={dataLead[0].perdrepoids}>
            <Radio.Button value="Oui">Oui</Radio.Button>
            <Radio.Button value="Non">Non</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Sexe"
          name="sexe"
          value={ubasic.sexe}
          onChange={(e) => {ubasic.sexe =  e.target.value }}
        >
          <Radio.Group defaultValue={dataLead[0].sexe}>
            <Radio.Button value="Homme">Homme</Radio.Button>
            <Radio.Button value="Femme">Femme</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
                <Button key="back" onClick={handleCancel}>
                  Annuler
                </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadBasic;