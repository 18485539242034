import React, {useState} from "react";
import { Form, Row, Col, Typography, Button, Space, Checkbox, Switch } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    vraisofaux: defaults
};
const AppStep12 = ({ next, prev, handleFormData, values }) => {
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const submitFormData = (e) => {
        if (switched === true) {
            values.vraisofaux = JSON.stringify(defaults);
        }
        else if (switched === false){
            values.vraisofaux = JSON.stringify(e.vraisofaux);
        }
        onChange(e);
        next();
    }
    const onSwitch = (e) => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }

    return(
        <Form 
            name="step12"
            onFinish={submitFormData}
            initialValues={initValues}
        >
            <Title level={2}>Sélectionnez tout ce qui est vrai pour vous :</Title>
                <Form.Item
                    name="vraisofaux"
                    checkedvalues={values.vraisofaux}
                    onChange={handleFormData("vraisofaux")}
                >
                    <CheckboxGroup
                        className="boxe"
                        style={{ width: '100%' }}
                        onChange={onChange}
                    >
                        <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Checkbox value="Je mange tard le soir" disabled={disabled}><Text>Je mange tard le soir</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Je ne dors pas assez" disabled={disabled}><Text>Je ne dors pas assez</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Je ne peux pas renoncer à manger des sucreries" disabled={disabled}><Text>Je ne peux pas renoncer à manger des sucreries</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="J'aime les boissons gazeuses" disabled={disabled}><Text>J'aime les boissons gazeuses</Text></Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="Je consomme beaucoup de sel" disabled={disabled}><Text>Je consomme beaucoup de sel</Text></Checkbox>
                        </Col>
                        </Row>
                    </CheckboxGroup>
              </Form.Item>
              <Row justify="end">
                <Col xs={{ span: 10 }} sm={{ span: 11 }} md={{ span: 11 }} lg={{ span: 9 }} xl={{ span: 7 }}>
                    <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
                </Col>
                <Col xs={{ span: 2 }} sm={{ span: 1 }} md={{ span: 1 }}>
                    <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
                        <Switch checked={switched} onChange={onSwitch} />
                    </Form.Item>
                </Col>
              </Row>
            <br />
            <Row justify="end">
            <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
                <Form.Item  className="steps-action">
                <Space style={{ width: '100%' }}>
                    <Button onClick={prev} type="primary" shape="circle" icon={<ArrowLeftOutlined />}/>
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Space>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    );
}
export default AppStep12;