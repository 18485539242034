import React from 'react';

import './App.css';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';
import './assets/css/steps.css';
import './assets/css/steps.scss';
import { Layout } from 'antd';
import Appheader from './components/common/header';
import Quiz from './views/quiz';
import AppFooter from './components/common/footer';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppBlog from './components/pages/blog/blog';
import PostPage from './components/pages/blog/post';
import Lead from './components/pages/dashboard/lead';
import Login from './components/pages/login';
import Dashboard from './components/pages/dashboard';
import Leaduser from './components/pages/dashboard/leaduser';
import ResetPassword from './components/pages/resetpassword';
import SetNewPassword from './components/pages/setnewpassword';
import MakeProgram from './components/pages/dashboard/program/makeprogram';
import DisplayProgram from './components/pages/dashboard/program/displayprogram';
import HomeIframe from './components/pages/iframes/iframehome';
import DisplayMeal from './components/pages/dashboard/program/displaymeal';
const { Header, Content, Footer } = Layout;

function App() {
  return (
    <Router>
      <Layout className="mainlayout">
        <Header>
          <Appheader/>
        </Header>
        <Content>
          <Switch >
            <Route exact path="/quiz" component={Quiz} />
            <Route exact path="/blog" component={AppBlog} />
            <Route path="/post/:id" component={PostPage} />
            <Route path="/lead/:id" component={Lead} />
            <Route path="/leadus/:id" component={Leaduser} />
            <Route path="/dashboard/:userName" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/resetpassword" component={ResetPassword} />
            <Route exact path="/login/setnewpassword" component={SetNewPassword} />
            <Route path="/makeprogram/:id" component={MakeProgram} />
            <Route path="/program/:id" component={DisplayProgram} />
            <Route path="/meal/:id" component={DisplayMeal} />
            <Route path="/" component={HomeIframe} />
          </Switch>
        </Content>
        <Footer>
          <AppFooter />
        </Footer>
      </Layout>
  </Router>
  );
}

export default App;
