import React, { useState } from 'react';
import { Row, Col } from 'antd';

import AppStep1 from './steps/step1'; import AppStep2 from './steps/step2'; import AppStep3 from './steps/step3'; 
import AppStep4 from './steps/step4'; import AppStep5 from './steps/step5'; import AppStep6 from './steps/step6';
import AppStep7 from './steps/step7'; import AppStep8 from './steps/step8'; import AppStep9 from './steps/step9';
import AppStep10 from './steps/step10'; import AppStep11 from './steps/step11'; import AppStep12 from './steps/step12';
import AppStep13 from './steps/step13'; import AppStep14 from './steps/step14'; import AppStep15 from './steps/step15';
import AppStep16 from './steps/step16'; import AppStep17 from './steps/step17'; import AppStep18 from './steps/step18';
import AppStep19 from './steps/step19'; import AppStep20 from './steps/step20'; import AppStep21 from './steps/step21';
import AppStep22 from './steps/step22'; import AppStep23 from './steps/step23'; import AppStep24 from './steps/step24';
import AppStep25 from './steps/step25'; import AppStep26 from './steps/step26'; import Final from './steps/final';
import AppEmail from './steps/email';


function AppQuiz() {
//state for steps
const [current, setCurrent] = useState(1);
//state for form data
const [formData, setFormData] = useState({
 perdrepoids: "", updates: false,  /* sexe: "", poidssouhaite: "", age: "", taille: "", poidsactuel: "",bodytype: "", journeetype: "",
  mauxestomac: "", tapoidsid: "", neppoids: "", nrepas: "",  pensees: "", workoutweek: "", energie: "", dormir: "",
  eau: "", temppreprepas: "", alimentssurgeles: "", appareils: "", allergies: "", legumes :"", cereales: "", produits: "", viande: "",
  fruits: "" */
})
// function for going to next step by increasing current state by 1
  const next = () => {
    setCurrent(current + 1);
  };
// function for going to previous step by decreasing current state by 1
  const prev = () => {
    setCurrent(current - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: value
  }));
  }
  switch (current) {
    // Voulez vous perdre du poids?
    case 1:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} >
              <div className="container-fluid">
                <AppStep1 next={next} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Sélectionnez votre sexe
  case 2:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep2 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Ok, quel est votre poids désiré?
    /* case 3:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }} >
              <div className="container-fluid">
                <AppStep3 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    ); */
    // Allons vérifier les mensurations de votre corps
    case 3:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep4 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Quel est votre type de corps?
    case 4:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep5 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Décrivez votre journée type
    case 5:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep6 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Changement de comportement vs régime restrictif
    case 6:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep7 next={next} prev={prev} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Ressentez-vous des maux d'estomac pendant la journée ?
    case 7:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep8 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Depuis combien de temps n'avez-vous pas atteint votre poids idéal ?
    case 8:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep9 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Combien de fois avez-vous essayé de perdre du poids ?
    case 9:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep10 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Combien de fois par jour voulez-vous manger ?
    case 10:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep11 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Sélectionnez tout ce qui est vrai pour vous :
    case 11:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep12 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Croyez-vous que...
    case 12:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep13 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Est-ce que tu fais du sport ?
    case 13:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep14 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Comment est votre énergie au jour le jour ?
    case 14:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep15 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Combien dormez-vous ?
    case 15:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep16 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Combien d'eau buvez-vous quotidiennement ?
    case 16:
    return (
      <div className="App">
          <Row id="feature" className="block featureBlock">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
              <div className="container-fluid">
                <AppStep17 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
              </div>
            </Col>
          </Row>
      </div>
    );
    // Combien de temps êtes-vous prêt à consacrer à la préparation d'un repas ?
    case 17:
    return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep18 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Vous aimeriez inclure des aliments surgelés ou prêts à cuire dans votre plan de repas?
    case 18:
    return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep19 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Quels appareils de cuisine avez-vous ?
    case 19:
      return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep20 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Avez-vous des restrictions alimentaires ou des allergies ?
    case 20:
    return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep21 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Cochez les légumes que vous souhaitez inclure :
    case 21:
      return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep22 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Cochez les céréales que vous souhaitez inclure :
    case 22:
      return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep23 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Cochez les produits que vous souhaitez inclure :
    case 23:
      return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep24 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Cochez la viande et le poisson que vous souhaitez inclure :
    case 24:
    return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep25 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Marquez les fruits que vous souhaitez inclure :
    case 25:
    return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppStep26 next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Entrez votre adresse E-mail :
    case 26:
    return (
      <div className="App">
        <Row id="feature" className="block featureBlock">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} >
            <div className="container-fluid">
              <AppEmail next={next} prev={prev} handleFormData={handleInputData} values={formData} />
            </div>
          </Col>
        </Row>
      </div>
    );
    // Resultat
    case 27:
    return (
              <Final values={formData} />
    );
    // default case to show nothing
    default:
      return (
        <div className="App">
        </div>
      );
    
  }
}

export default AppQuiz;