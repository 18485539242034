import React, { useState } from 'react';
import EditLeadMeasurments from '../leadedit/editleadmeasurements';
import { Descriptions, Button, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons';

const LeadMeasurments = ({ dataLead, setIsInitialRender }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsInitialRender(true);
            setVisible(false);
            setConfirmLoading(false);
        }, 500);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
        <Descriptions column={{ md: 2, sm: 1, xs: 1 }}  title="Les Mesures" extra={<Button onClick={showModal} key="1" type="primary" shape="circle" icon={<SettingOutlined />} />}>
            <Descriptions.Item label="Age">{dataLead[0].age}</Descriptions.Item>
            <Descriptions.Item label="Taille">{dataLead[0].taille}</Descriptions.Item>
            <Descriptions.Item label="Poids actuel">{dataLead[0].poidsactuel}</Descriptions.Item>
            <Descriptions.Item label="Poids souhaitée">{dataLead[0].poidssouhaite}</Descriptions.Item>
            <Descriptions.Item label="Type de corps">{dataLead[0].bodytype}</Descriptions.Item>
        </Descriptions>

        <Modal
            title="Les Mesures"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
           <EditLeadMeasurments dataLead={dataLead} handleOk={handleOk} handleCancel={handleCancel} confirmLoading={confirmLoading}/>
        </Modal>
        </>
    );
}
export default LeadMeasurments;