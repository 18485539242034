import React, {useState} from 'react';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import { Checkbox, Form, Button, Typography, Row, Col, Divider, Switch, Image } from 'antd';
import Turquie from '../../../home/steps/img/Turquie.svg'; import Bœuf from '../../../home/steps/img/Bœuf.svg';
import Poulet from '../../../home/steps/img/Poulet.svg'; import Poisson from '../../../home/steps/img/Poisson.svg';
import Fruitmer from '../../../home/steps/img/Fruitmer.svg';
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
var defaults = [];
var initValues = {
    viande: defaults
};
const EditLeadViande = ({ dataLead, handleOk, handleCancel, confirmLoading }) => { 
    const [switched, setSwitched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let vf = [];
    const uviande ={
        Id: dataLead[0].Id,
        viande: dataLead[0].viande,
      };
    function onChange(checkedvalues) {
        return vf.push(checkedvalues);
    }
    const onSwitch = () => {
        setSwitched(!switched);
        setDisabled(!disabled);
    }
    const submitForm = (e) => {
        if (switched === true) {
            uviande.viande = JSON.stringify(defaults);
        }
        else if (switched === false){
            uviande.viande = JSON.stringify(e.viande);
        }
        onChange(e);
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/leads/uviande`, uviande)
          .then()
          .catch(err => {console.log(err.response.data.message)})
        handleOk();
    }
    return(
      <Form onFinish={submitForm} initialValues={initValues} >
        <Form.Item
          name="viande"
          value={uviande.viande}
          onChange={(e) => {uviande.viande = e.target.value }}
        >
          <CheckboxGroup
            className="boxe"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Checkbox value="Turquie" disabled={disabled}><Text><Image width={18} src={Turquie} preview={false}/> Turquie</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Bœuf" disabled={disabled}><Text><Image width={18} src={Bœuf} preview={false}/> Bœuf</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Poulet" disabled={disabled}><Text><Image width={18} src={Poulet} preview={false}/> Poulet</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Poisson" disabled={disabled}><Text><Image width={18} src={Poisson} preview={false}/> Poisson</Text></Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Fruit de mer" disabled={disabled}><Text><Image width={18} src={Fruitmer} preview={false}/> Fruit de mer</Text></Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        
        <Row justify="end">
          <Col xs={{ span: 9 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>
            <Text style={{lineHeight:2.3}} >Aucune de ces réponses </Text>
          </Col>
          <Col span={2}>
            <Form.Item layout="inline" valuePropName="checked" name="firstSwitch">
              <Switch checked={switched} onChange={onSwitch} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col xs={{ span: 4, pull: 3 }} sm={{ span: 4, pull: 3 }} md={{ span: 4, pull: 3 }}>      
            <Form.Item>
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 4, pull: 2 }} sm={{ span: 4, pull: 2 }} md={{ span: 4, pull: 2 }}>      
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} >
                Enregistrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}
export default EditLeadViande;